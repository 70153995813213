import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import UserInfo from "@/core/services/UserInfo";
import { checkAutoLogin, exitAutoLogin } from "@/core/services/AutoLogin";
import { useRouter } from "vue-router";

// import { useI18n } from "vue-i18n/index";
export interface User {
  id: string;
  first_name: string;
  last_name: string;
  picture: string;
  type: string;
  password: string;
  api_token: string;
  mobile: string;
  credit: string;
  birth_date: string;
  status: string;
  email: string;
  created_at: string;
  unique_id: string;
  unread_tickets: number;
  unread_conversations: number;
  app_key: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  private router = useRouter(); // تغییر اینجا

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CONVERSATION_COUNT](count) {
    this.user.unread_conversations = count;
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    this.user = data.data.user;
    this.errors = [];

    // window.localStorage.setItem("user_id", this.user.id);
    JwtService.setUserId(this.user.id);
    if (data.data.user.last_conversation_id) {
      window.localStorage.setItem(
        "ConversationId",
        data.data.user.last_conversation_id
      );
    }
    JwtService.saveToken(data.data.user.access_token);
    this.user.unread_tickets = data.data.unread_tickets;
    this.user.unread_conversations = data.data.unread_conversations;
    UserInfo.saveUser(this.user);
    window.localStorage.setItem("app_key", data.data.app_key);
    window.localStorage.setItem('permissions' , JSON.stringify(data.data.user.permissions) )
    window.localStorage.setItem("app_key", data.data.app_key);
    if (data.data.s)
      window.localStorage.setItem("socket", JSON.stringify(data.data.s));
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    JwtService.destroyUserId();
    UserInfo.destroyUser();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise((resolve, reject) => {
      const params = {
        ...credentials,
      };
      ApiService.post("auth/login", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          window.localStorage.setItem("userLoginOrRegister", "login");
          
          resolve(data);
        })
        .catch(({ response }) => {
          if (response && response.data)
            this.context.commit(Mutations.SET_ERROR, response.data);
          reject(response.data); // ارسال خطا به عنوان مقدار Promise برگشتی
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    if (checkAutoLogin()) {
      exitAutoLogin();
    }
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/register", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          window.localStorage.setItem("userLoginOrRegister", "sign_up");
          resolve(data); // ارسال داده به عنوان مقدار Promise برگشتی
        })
        .catch(({ response }) => {
          if (response && response.data)
            this.context.commit(Mutations.SET_ERROR, response.data);
          reject(response.data); // ارسال خطا به عنوان مقدار Promise برگشتی
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const params = {
      params: {
        ...payload,
      },
    };
    return ApiService.query("forgot_password", params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const params = {
        ua: navigator.userAgent,
        with_commission: true,
        check_use_free_package: true,
      };
      ApiService.post("verify-token", params)
        .then(({ data }) => {
          window.localStorage.setItem('whatsapp_default_method', data.data.whatsapp_default_method)
          window.localStorage.setItem("app_key", data.data.app_key);
          if (data.data.user.last_conversation_id) {
            window.localStorage.setItem(
              "ConversationId",
              data.data.user.last_conversation_id
            );
          }
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.code === 401) {
            this.context.commit(Mutations.PURGE_AUTH);
            // Swal.fire({
            //   title: response.data.message,
            //   icon: "error",
            //   html: "Please log in again",
            //   showCloseButton: true,
            //   focusConfirm: true,
            //   confirmButtonText: `<i class="fa fa-thumbs-up text-white"></i> ${translate(
            //     "I will log in again"
            //   )}`,
            // });
          } else {
            // Swal.fire({
            //   title: translate(
            //     "Unfortunately there was an error in receiving the information"
            //   ),
            //   text: translate(
            //     "Please check your internet connection and refresh the page"
            //   ),
            //   icon: "error",
            //   buttonsStyling: false,
            //   confirmButtonText: translate("Ok"),
            //   customClass: {
            //     confirmButton: "btn btn-danger",
            //   },
            // }).then(() => {
            //   // this.context.commit(Mutations.PURGE_AUTH);
            // });
          }
          // this.context.commit(Mutations.SET_ERROR, response.data);
          // this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  // @Action
  // [Actions.UPDATE_USER](payload) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }
}
