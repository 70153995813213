import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type === 'date')
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(_ctx.classes)
      }, _toDisplayString(_ctx.country ? _ctx.date["jalali"] ?? "-" : _ctx.date["gregorian"] ?? "-"), 3))
    : (_openBlock(), _createElementBlock("span", {
        key: 1,
        class: _normalizeClass(_ctx.classes)
      }, _toDisplayString(_ctx.date["diff_for_humans"]), 3))
}