const USER_INFO_KEY = "user_info" as string;

export const getUser = () => {
  const info = window.localStorage.getItem(USER_INFO_KEY);
  return JSON.parse(String(info));
};

export const saveUser = (info: any) => {
  window.localStorage.setItem(USER_INFO_KEY, JSON.stringify(info));
};

export const destroyUser = (): void => {
  window.localStorage.removeItem(USER_INFO_KEY);
};

export default { getUser, saveUser, destroyUser };
