import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.onlyIcon === false)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(["fw-lighter", [`badge badge-light-` + _ctx.statusColor( _ctx.status), _ctx.classes]]),
        style: {"width":"fit-content"}
      }, [
        (_ctx.showIcon === 'yes')
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: _normalizeClass(["me-1", `bi bi-` + _ctx.statusIcon(_ctx.status) + ' text-' + _ctx.statusColor(_ctx.status)])
            }, null, 2))
          : _createCommentVNode("", true),
        _createTextVNode(_toDisplayString(_ctx.translate(_ctx.status === 'stop' ? 'has been stopped' :_ctx.status === 'need_login' ? 'Need to connect' : _ctx.status)), 1)
      ], 2))
    : (_openBlock(), _createElementBlock("span", {
        key: 1,
        class: _normalizeClass([`badge badge-circle badge-light-` + _ctx.statusColor(_ctx.status), _ctx.classes])
      }, [
        (_ctx.showIcon === 'yes')
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: _normalizeClass(["me-1", `bi bi-` + _ctx.statusIcon(_ctx.status) + ' text-' + _ctx.statusColor(_ctx.status)])
            }, null, 2))
          : _createCommentVNode("", true)
      ], 2))
}