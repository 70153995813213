
import { computed, defineComponent } from "vue";
import userInfo from "@/core/services/UserInfo";
import { translate } from "@/core/services/HelpersFun";
export default defineComponent({
  name: "kt-date_handler",
  props: {
    date: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "date", // date | human
    },
    classes: {
      type: String,
      default: "",
    },
  },
  setup() {
    const country = computed(() => {
      return localStorage.getItem("lang") === "fa";
    });

    return {
      translate,
      country,
    };
  },
});
