import { createApp } from "vue";
import App from "./App.vue";
import i18n from "@/core/plugins/i18n";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import fa from "element-plus/es/locale/lang/fa";
import Status from "@/components/Status.vue";
import EmptyDataReplacement from "@/components/EmptyDataReplacement.vue";
import CurrencyCode from "@/components/CurrencyCode.vue";
import DateHandler from "@/components/DateHandler.vue";
import { setupInputDigitDirective } from "@/modules/setupInputDigitDirective";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import { createPinia } from "pinia";
import "@/core/plugins/prismjs";
import "bootstrap";

const pinia = createPinia();

const app = createApp(App);
app.use(pinia);
app.use(i18n);
app.use(store);
app.use(router);
app.use(ElementPlus, {
  locale: fa,
});

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

setupInputDigitDirective(app);

app.component("Status", Status);
app.component("Currency", CurrencyCode);
app.component("Empty", EmptyDataReplacement);
app.component("Date", DateHandler);

app.mount("#app");
