import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import UserInfo from "@/core/services/UserInfo";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    // redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/management",
        name: "مدیریت",
        component: () => import("@/views/pages/Admin/Management.vue"),
        meta: { authorize: ["admin", "super_admin"] },
      },
      {
        path: "/management/dashboard/bots-info",
        name: "مدیریت ربات ها",
        component: () => import("@/views/pages/Admin/Management.vue"),
        meta: { authorize: ["admin", "super_admin"] },
      },
      {
        path: "/management/otp-codes",
        name: "مدیریت کد های تایید",
        component: () => import("@/views/pages/Admin/otpCodes/otpCodesTable.vue"),
        meta: { authorize: ["admin", "super_admin"] },
      },
      {
        path: "/management/builder",
        name: "builder",
        component: () => import("@/views/pages/Admin/Builder.vue"),
      },
      {
        path: "/management/users",
        name: "UsersListTable",
        component: () => import("@/views/pages/Admin/users/UsersListTable.vue"),
      },
      {
        path: "/management/add-user",
        name: "افزودن کاربر",
        component: () => import("@/views/pages/Admin/users/addNewUser.vue"),
        meta: { authorize: ["admin", "super_admin"] },
      },
      {
        path: "/management/users/:id/history",
        name: "UserHistory",
        component: () => import("@/views/pages/Admin/users/UsersHistory.vue"),
      },
      {
        path: "/management/packages",
        name: "پکیج ها",
        component: () =>
          import("@/views/pages/Admin/packages/PackagesManagementTable.vue"),
      },
      {
        path: "/management/campaigns",
        name: "advertise-campaigns",
        component: () =>
          import(
            "@/views/pages/Admin/advertiseCampaigns/AdvertiseCampaigns.vue"
          ),
      },
      {
        path: "/management/potential-customer",
        name: "potential-customer",
        component: () =>
          import("@/views/pages/Admin/potentialCustomer/PotentialCustomer.vue"),
      },
      {
        path: "/management/tickets",
        name: "تیکت ها",
        component: () => import("@/views/pages/other/ticket/TicketsTable.vue"),
      },
      {
        path: "/management/conversation",
        name: "پشتیبانی آنلاین",
        component: () =>
          import("@/views/pages/Admin/conversation/CustomerLiveChat.vue"),
      },
      {
        path: "/management/transactions",
        name: "تراکنش ها",
        component: () =>
          import(
            "@/views/pages/Admin/transactions/TransactionsManagementTable.vue"
          ),
      },
      {
        path: "/management/expiring-bots",
        name: "پکیج های درحال اتمام",
        component: () => import("@/views/pages/Admin/other/ExpiringBots.vue"),
      },
      {
        path: "/management/servers",
        name: "سرورها",
        component: () => import("@/views/pages/Admin/servers/ServerList.vue"),
      },
      {
        path: "/management/discounts",
        name: "تخفیف ها",
        component: () =>
          import("@/views/pages/Admin/discounts/discountsTable.vue"),
      },
      {
        path: "/management/agency-request",
        name: "درخواست های نمایندگی",
        component: () =>
          import("@/views/pages/Admin/agency/agencyRequestList.vue"),
      },
      {
        path: "/management/bots",
        name: "مدیریت ربات ها",
        component: () => import("@/views/pages/Admin/bots/Bots.vue"),
      },
      {
        path: "/management/bulk-message",
        name: "bulkMessageManagement",
        component: () =>
          import("@/views/pages/services/bulkMessage/MultipleMessages.vue"),
      },
      {
        path: "/management/Secretary",
        name: "SecretaryManagement",
        component: () =>
          import("@/views/pages/Admin/Secretary/SecretaryResponses.vue"),
      },
      {
        path: "/management/api",
        name: "apiManagement",
        component: () =>
          import("@/views/pages/services/api/component/Notifications.vue"),
      },
      {
        path: "/management/cooperationInSales",
        name: "مدیریت همکاری در فروش",
        component: () =>
          import(
            "@/views/pages/Admin/cooperationInSale/ManagementCooperationInSale.vue"
          ),
      },
      {
        path: "/management/adminsList",
        name: "adminsList",
        component: () =>
          import(
            "@/views/pages/Admin/adminsList.vue"
          ),
      },
      {
        path: "/management/notifications-management",
        name: "اعلان های ارسال شده",
        component: () =>
          import("@/views/pages/Admin/other/NotificationsManagement.vue"),
      },
      {
        path: "/management/notifications-management",
        name: "اعلان های ارسال شده",
        component: () =>
          import("@/views/pages/Admin/other/NotificationsManagement.vue"),
      },
      {
        path: "/management/notice",
        name: "اطلاعیه ها",
        component: () =>
          import("@/views/pages/Admin/other/Notice/NoticeManagement.vue"),
      },
      {
        path: "/management/buy-link-generate",
        name: "buy-link-generate",
        component: () => import("@/views/pages/buy/HandleBuy.vue"),
      },
      {
        path: "/management/bot-reports",
        name: "bot-reports",
        component: () => import("@/views/pages/Admin/reports/botRepots.vue"),
      },
    ],
  },

  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/pages/dashboard/Dashboard.vue"),
      },
      {
        path: "/welcome",
        name: "welcome",
        component: () => import("@/views/pages/welcome/Welcome.vue"),
      },
      {
        path: "/bot-connections",
        name: "bot-connections",
        component: () =>
          import("@/views/pages/SubscriptionConnections/TablesShowBots.vue"),
      },
      {
        path: "/bot-connections/connect/:bot_id/:platform?/:transaction_id?",
        name: "connect-to-platform",
        component: () =>
          import(
            "@/views/pages/SubscriptionConnections/component/NewConnect.vue"
          ),
      },
      {
        path: "/buy/:bot_id?",
        name: "buy",
        component: () => import("@/views/pages/buy/HandleBuy.vue"),
        // component: () => import("@/views/pages/buy/Buy/BuyHandler.vue"),
        // component: () => import("@/views/pages/buy/buy-new.vue"),
        // component: () => import("@/components/VerifyEmail.vue"),
        // component: () => import("@/components/Invoice.vue"),
      },
      {
        path: "/credit-charge",
        name: "credit-charge",
        component: () => import("@/views/pages/buy/CreditCharge.vue"),
      },
      {
        path: "/request-payment",
        name: "request-payment",
        component: () => import("@/views/pages/buy/RequestPayment.vue"),
      },
    ],
  },

  {
    path: "/services",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/services",
        name: "services",
        component: () => import("@/views/pages/services/Services.vue"),
      },
      {
        path: "/services/bulk-message",
        name: "bulk-message",
        component: () =>
          import("@/views/pages/services/bulkMessage/MultipleMessages.vue"),
      },
      {
        path: "/services/archive-message",
        name: "archive-message",
        component: () =>
          import("@/views/pages/services/bulkMessage/message_archive.vue"),
      },
      {
        path: "/services/bulk-message/add-recipient",
        name: "add-recipient",
        component: () =>
          import(
            "@/views/pages/services/bulkMessage/component/InputNumbersWays.vue"
          ),
      },
      {
        path: "/services/Secretary",
        name: "Autoresponder",
        component: () =>
          import("@/views/pages/services/secretary/SecretaryTableNew.vue"),
      },
      {
        path: "/services/user-secretary-response",
        name: "AutoresponderMessages",
        component: () =>
          import("@/views/pages/services/secretary/userSecretaryResponse.vue"),
      },
      {
        path: "/services/Secretary/builder/:bot_id?",
        name: "مدیریت پاسخوگی خودکار",
        component: () =>
          import("@/views/pages/services/secretary/CreateSecretary.vue"),
      },
      {
        path: "/services/multi-user",
        name: "my-whatsapp-messages",
        component: () =>
          import("@/views/pages/services/multiUser/MultiUser.vue"),
      },
      {
        path: "/services/reservation",
        name: "reservation",
        component: () =>
          import("@/views/pages/services/reservation/Reservation.vue"),
      },
      {
        path: "/services/api",
        name: "api",
        component: () => import("@/views/pages/services/api/ApiList.vue"),
      },
      {
        path: "/services/api/document",
        name: "apiDocument",
        component: () => import("@/views/pages/services/api/Documents.vue"),
      },
      {
        path: "/services/plugins",
        name: "plugins",
        component: () => import("@/views/pages/services/api/plugins.vue"),
        // meta: { authorize: ["admin", "super_admin"] },
      },
      {
        path: "/services/wpPlugin",
        name: "wpPlugin",
        component: () => import("@/views/pages/services/api/wpPlugin.vue"),
        // meta: { authorize: ["admin", "super_admin"] },
      },
      {
        path: "/services/pythonPlugin",
        name: "pythonPlugin",
        component: () => import("@/views/pages/services/api/pythonPlugin.vue"),
        // meta: { authorize: ["admin", "super_admin"] },
      },
      {
        path: "/services/phpPlugin",
        name: "phpPlugin",
        component: () => import("@/views/pages/services/api/phpPlugin.vue"),
        // meta: { authorize: ["admin", "super_admin"] },
      },
      {
        path: "/services/questionnaire",
        name: "questionnaire",
        component: () =>
          import(
            "@/views/pages/services/onlineQuestionnaire/Questionnaire.vue"
          ),
      },
      {
        path: "/services/questionnaire/maker",
        name: "ایجاد پرسشنامه",
        component: () =>
          import(
            "@/views/pages/services/onlineQuestionnaire/OnlineQuestionnaireMaker.vue"
          ),
      },
      {
        path: "/services/questionnaire/answers",
        name: "سوالات پرسشنامه",
        component: () =>
          import(
            "@/views/pages/services/onlineQuestionnaire/QuestionnaireAnswers.vue"
          ),
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/pages/other/settings/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () =>
              import("@/views/pages/other/settings/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () =>
              import("@/views/pages/other/settings/Settings.vue"),
          },
          {
            path: "credit",
            name: "credit-logs",
            component: () =>
              import(
                "@/views/pages/other/cooperationInSales/components/Credit.vue"
              ),
          },
        ],
      },
    ],
  },

  {
    path: "/other",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/webhook",
        name: "webhook",
        component: () => import("@/views/pages/other/webHook/Webhook.vue"),
      },
      {
        path: "/webhook/document",
        name: "webhookDocument",
        component: () =>
          import("@/views/pages/other/webHook/webhookDocuments.vue"),
      },
      {
        path: "/cooperationInSales",
        name: "همکاری در فروش",
        component: () =>
          import(
            "@/views/pages/other/cooperationInSales/CooperationInSales.vue"
          ),
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () =>
          import("@/views/pages/services/api/component/Notifications.vue"),
      },
      {
        path: "/support",
        name: "support",
        component: () => import("@/views/pages/other/ticket/TicketsTable.vue"),
      },
      {
        path: "/support/Introducing-the-services",
        name: "Introducing-the-services",
        component: () => import("@/views/pages/services/Services.vue"),
      },
      {
        path: "/services/todo",
        name: "todo",
        component: () => import("@/views/pages/other/notes/Board.vue"),
      },
      {
        path: "/services/todo/category",
        name: "todoCategory",
        component: () =>
          import("@/views/pages/other/notes/category/BoardManagement.vue"),
      },
      {
        path: "/phone-book",
        name: "phone-book",
        component: () =>
          import("@/views/pages/other/phoneBook/PhonebookTable.vue"),
      },
      {
        path: "/transactions",
        name: "transactions",
        component: () =>
          import("@/views/pages/other/transactions/TransactionsTable.vue"),
      },
      {
        path: "/help",
        name: "راهنما و آموزش",
        component: () => import("@/views/pages/other/help/help.vue"),
      },
      {
        path: "/privacy",
        name: "قوانین و مقررات",
        component: () => import("@/views/pages/other/privacy/privacy.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in/:ref?",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/welcome-wizard",
    name: "welcome-wizard",
    component: () => import("@/views/pages/welcome/Welcome-wizard.vue"),
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/agency-request",
        name: "Agency Request",
        component: () => import("@/views/pages/other/agency/agency.vue"),
      },
    ],
  },

  {
    path: "/bank",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: UserInfo.getUser()?.crawler_enabled ? "/bank/requestList" : "/404",
        name:UserInfo.getUser()?.crawler_enabled ?  "requestList" : "404",
        component: () =>UserInfo.getUser()?.crawler_enabled ?  import("@/views/pages/bank/requestsList.vue") : import("@/views/crafted/authentication/Error404.vue"),
      },
      {
        path: UserInfo.getUser()?.crawler_enabled ? "/bank/subscriptionList" : "/404",
        name: UserInfo.getUser()?.crawler_enabled ? "subscriptionList" : "404",
        component: () =>
          UserInfo.getUser()?.crawler_enabled ? import("@/views/pages/bank/subscriptionList.vue") : import("@/views/crafted/authentication/Error404.vue"),
      },
      {
        path: "/bank/connect/:bot_id",
        name: "connect",
        component: () =>
          import("@/views/pages/bank/components/connetc.vue"),
      },
    ],
  },


  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  const userInfo = UserInfo.getUser();
  if (to.matched.some((record) => record.meta.authorize)) {
    const role = to.meta.authorize as any;
    let userType = "user";
    if (typeof userInfo == "object" && userInfo["type"] !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      userType = userInfo["type"];
      if (role.includes(userType)) {
        next();
        return;
      }
      next("/dashboard");
      return;
    }
  }
  next();
});

// router.beforeEach((to, from, next) => {
//   // reset config to initial state
//   store.commit(Mutations.RESET_LAYOUT_CONFIG);
//   store.dispatch(Actions.VERIFY_AUTH);
//
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
//   // const currentUser = UserInfo.getUser();
//
//   // let currentUserType;
//   // if (to.meta?.authorize) {
//     // currentUserType = currentUser.type;
//
//     // if (!currentUserType) { return next({ path: "/dashboard" });
//     // }
//     // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//     // // @ts-ignore
//     // if (to.meta?.authorize.length && !to.meta?.authorize.includes(currentUserType)) {
//     //   return next({ path: "/dashboard" });
//     // }
//   // }
//   return next();
// });

export default router;
