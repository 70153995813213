const ID_TOKEN_KEY = "id_token" as string;
const USER_ID = "user_id" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description get token form localStorage
 */
export const getUserId = (): string | null => {
  return window.localStorage.getItem(USER_ID);
};

export const destroyUserId = () => {
  window.localStorage.removeItem(USER_ID);
};

export const setUserId = (id) => {
  window.localStorage.setItem(USER_ID, id);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getUserId,
  destroyUserId,
  setUserId,
};
