import { Directive, App } from "vue";

interface InputDigitOptions {
  block?: number;
  delimiter?: string;
}

const inputDigitDirective: Directive = {
  updated(el, binding) {
    const element = el.tagName === "INPUT" ? el : el.querySelector("input");
    const event = new Event("input", { bubbles: true });

    if (element.value) {
      const { block, delimiter } = getOptions(binding);
      const elementValue = element.value
        .toString()
        .replace(new RegExp(delimiter || "", "g"), "");

      element.value = convertToEnglish(elementValue, block, delimiter);
    }

    element.dispatchEvent(event);
  },
};

function getOptions(binding: any): InputDigitOptions {
  return binding.value || {};
}

function convertToEnglish(
  str: string,
  block?: number,
  delimiter?: string
): string {
  const toEn: Record<string, string> = {
    "۰": "0",
    "۱": "1",
    "۲": "2",
    "۳": "3",
    "۴": "4",
    "۵": "5",
    "۶": "6",
    "۷": "7",
    "۸": "8",
    "۹": "9",
    "٠": "0",
    "١": "1",
    "٢": "2",
    "٣": "3",
    "٤": "4",
    "٥": "5",
    "٦": "6",
    "٧": "7",
    "٨": "8",
    "٩": "9",
  };

  const convertedStr = Array.from(str)
    .map((char) => toEn[char] || char)
    .join("");

  return block && delimiter
    ? addDelimiter(convertedStr, block, delimiter)
    : convertedStr;
}

function addDelimiter(str: string, size: number, delimiter = ""): string {
  const rgx = `\\B(?=(\\d{${size}})+(?!\\d))`;
  const replacementRgx = new RegExp(rgx, "g");
  return str.replace(replacementRgx, delimiter);
}

// Register the directive globally
export function setupInputDigitDirective(app: App): void {
  app.directive("input-digit", inputDigitDirective);
}

// examples
// <input v-model="userInput" v-input-digit="{ block: 3, delimiter: ',' }"/>
// <input v-model="userInput" v-input-digit />
