
import { computed, defineComponent, ref } from "vue";
import { statusColor, translate, statusIcon } from "@/core/services/HelpersFun";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "kt-empty-data-replacement",
  components: {},
  setup() {
    const route = useRoute();
    const current = computed(() => {
      return data.value.find((item) => item.name === route.name);
    });
    const data = ref([
      {
        name: "bot-connections",
        message:
          "You have not subscribed yet In order to use Inboxio services you must prepare a subscription and connect your desired messenger to it",
        image: "/media/illustrations/sigma-1/9.png",
        title_btn: "connect",
        modal_id: "",
        link: "",
      },
      {
        name: "bulk-message",
        message:
          "You have not sent a message yet You can use the send message button to send a message",
        image: "/media/illustrations/sigma-1/16.png",
        title_btn: "Sending bulk messages",
        modal_id: "kt_modal_2",
        link: "",
      },
      {
        name: "api",
        message:
          "You have not used the web service yet You can use this section to connect to the website mobile application or any other program to send notifications Use the Create Web Service button to create a web service",
        image: "/media/illustrations/sigma-1/2.png",
        title_btn: "Create a web service",
        modal_id: "kt_modal_api_crud",
        link: "",
      },
      {
        name: "Autoresponder",
        message: "You have not created an autoresponder yet",
        image: "/media/illustrations/sigma-1/12.png",
        title_btn: "Create Autoresponder",
        modal_id: "kt_modal_create_wel_come_secretary",
        link: "",
      },
      {
        name: "support",
        message:
          "So far no ticket has been registered by you You can use the tickets to solve your problems",
        image: "/media/illustrations/sigma-1/12.png",
        title_btn: "Create ticket",
        modal_id: "kt_modal_crate_ticket_modal",
        link: "",
      },
      {
        name: "phone-book",
        message:
          "You have not created a phonebook yet You can use the phone book to manage your numbers",
        image: "/media/illustrations/sigma-1/8.png",
        title_btn: "Create a new notebook",
        modal_id: "kt_modal_phonebook",
        link: "",
      },
      {
        name: "webhook",
        message:
          "Webhooks are used for instant notification and real-time events in systems For example a webhook can be used to notify important events such as sending a message updating a status or other events You can receive this information in your system through an API and perform the necessary operations",
        image: "/media/illustrations/sigma-1/10.png",
        title_btn: "",
        modal_id: "",
        link: "",
      },
    ]);

    return {
      route,
      statusColor,
      translate,
      statusIcon,
      current,
    };
  },
});
