
import { defineComponent } from "vue";
import { statusColor, statusIcon } from "@/core/services/HelpersFun";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "kt-status",
  props: {
    status: { type: String },
    showIcon: { type: String, default: "yes" },
    onlyIcon: { type: Boolean, default: false },
    classes: { type: String, default: "" },
  },
  components: {},
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (!text) return "not_found";
      if (te(text)) return t(text);
      return text;
    };
    return {
      statusColor,
      translate,
      statusIcon,
    };
  },
});
