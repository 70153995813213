import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "row w-100 justify-content-between mt-9 pb-0",
  style: {}
}
const _hoisted_2 = { class: "col-md-7 align-items-center justify-content-between ps-xl-13" }
const _hoisted_3 = { class: "mb-6 pt-6" }
const _hoisted_4 = { class: "fs-4 fw-semibold text-danger me-2 d-block lh-1 pb-2 opacity-75" }
const _hoisted_5 = {
  key: 0,
  class: "fw-semibold text-grey-700 fs-6 mb-8 d-block opacity-75"
}
const _hoisted_6 = { class: "d-flex flex-column flex-sm-row d-grid gap-2" }
const _hoisted_7 = ["data-bs-target"]
const _hoisted_8 = { class: "col-md-5 align-items-center" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translate("pay attention")), 1)
      ]),
      (_ctx.current?.message)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.translate(_ctx.current?.message)), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.current?.name === 'bot-connections')
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: "/welcome-wizard",
              class: "btn btn-primary flex-shrink-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate('Click to test for free')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.current?.modal_id !== '')
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              "data-testid": "open-phonebook-modal",
              href: "#",
              class: "btn btn-primary flex-shrink-0",
              "data-bs-toggle": "modal",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
              "data-bs-target": `#${_ctx.current.modal_id}`
            }, _toDisplayString(_ctx.translate(_ctx.current.title_btn)), 9, _hoisted_7))
          : _createCommentVNode("", true),
        (_ctx.current?.link !== '')
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 2,
              to: _ctx.current.link,
              class: "btn btn-primary flex-shrink-0",
              "data-bs-toggle": "modal",
              "data-bs-target": "#kt_modal_create_app"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate(_ctx.current.title_btn)), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("img", {
        class: "mx-auto theme-light-show",
        src: _ctx.current.image,
        style: {"max-width":"inherit"},
        alt: ""
      }, null, 8, _hoisted_9)
    ])
  ]))
}