import { computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";

export const translate = (text) => {
  const { t, te } = useI18n();
  if (te(text)) return t(text);
  return text;
};

export const statusColor = (status) => {
  switch (status) {
    case "active":
    case "ready":
    case "yes":
    case "all_done":
    case "answered":
    case "reply_user":
    case "paid":
    case "sent":
    case "finished":
    case "true":
    case "enable":
      return "success";
    case "no":
    case "high":
    case "stop":
    case "failed":
    case "delay":
    case "closed":
    case "error":
    case "off":
    case "false":
    case "de_active":
    case "deactive":
    case "not_supported":
    case "no_subscription":
    case "disable":
    case "Expiration sending time":
      return "danger";
    case "expired":
      return "danger";
    case "medium":
    case "connecting":
    case "setup":
      return "primary";
    case "need_login":
    case "need_pay":
    case "pending":
      case "refund":
    case "waiting_to_reply":
      return "warning";
    case "sending":
    case "restricted":
    case "reply_operator":
      return "info";
    default:
      return "light";
  }
};

export const statusIcon = (status) => {
  switch (status) {
    case "active":
    case "ready":
    case "yes":
    case "all_done":
    case "answered":
    case "reply_user":
    case "finished":
    case "paid":
    case "sent":
    case "true":
      return "check2-all";
    case "no":
    case "high":
    case "stop":
    case "failed":
    case "delay":
    case "closed":
    case "error":
    case "de_active":
    case "deactive":
    case "false":
    case "off":
    case "Expiration sending time":
    case "not_supported":
    case "no_subscription":
      case "refund":
      return "exclamation-circle";
    case "medium":
      return "check-lg";
    case "restricted":
      return "shield-slash-fill";
    case "setup":
      return "qr-code-scan";
    case "expired":
    case "need_login":
    case "need_pay":
    case "pending":
    case "waiting_to_reply":
      return "exclamation-triangle";
    case "sending":
    case "reply_operator":
      return "info-circle";
    default:
      return "info-lg";
  }
};

// export const checkPackagePlatform = (platforms, platform) => {
//   if (platforms !== undefined) return platform in platforms;
//   return false;
// };

export const checkPackagePlatform = (platforms, platform) => {
  if (
    platform !== undefined &&
    Array.isArray(platforms) &&
    platforms.length > 0
  )
    return platforms.includes(platform);
  if (platforms !== undefined) return platform in platforms;
  return false;
};

export const checkBotHasActivePlatform = (platforms, status = "ready") => {
  if (platforms && Array.isArray(platforms)) {
    const check = platforms.find((item) => item.status === status);
    if (check) return true;
  }

  return false;
};

export const setLangWithPrevUrl = () => {
  const url = useRoute().query.lang as string;
  if (url && localStorage.getItem("lang") !== url) {
    localStorage.setItem("lang", url);
    window.location.reload();
  }
};

export const setLangFont = () => {
  const lang = computed(() => {
    return localStorage.getItem("lang");
  });

  if (lang.value === "tr") {
    document.body.classList.add("other-font");
  } else {
    document.body.classList.remove("fa-font");
  }
};

export const getLocale = () => {
  const lang = computed(() => {
    return localStorage.getItem("lang");
  });

  return lang.value === "en" || lang.value === "tr"
    ? localStorage.getItem("lang")
    : "fa";
};

export const pusherKey = computed(() => {
  return window.localStorage.getItem("app_key");
});

export default {
  statusColor,
  translate,
  statusIcon,
  checkPackagePlatform,
  checkBotHasActivePlatform,
  setLangWithPrevUrl,
  getLocale,
  pusherKey,
};
