import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import UserInfo from "@/core/services/UserInfo";
import userInfo from "@/core/services/UserInfo";
import jwtService from "@/core/services/JwtService";

const PREVIOUS_TOKEN_KEY = "previous_token" as string;
const PREVIOUS_USER_INFO = "previous_user_info" as string;
let wait  = false
export const checkAutoLogin = () => {
  return getPreviousToken() !== null;
};

export const autoLogin = async (id) => {
  if (checkAutoLogin()) {
    alert(
      "شما در پنل کاربر دیگری هستید و تا زمان خروج از پنل کاربر قبلی، اجازه ورود به پنل کاربر جدید را ندارید."
    );
    return false;
  }
  if(wait === true){
    alert(
      "سیستم در حال پردازش ورود شماست"
    );
    return false;
  }
  
  else {
    wait = true
    ApiService.post(`admin/user/${id}/auto-login`, []).then(async({ data }) => {
      window.localStorage.setItem(
        PREVIOUS_USER_INFO,
        JSON.stringify(userInfo.getUser())
      );

      window.localStorage.setItem(
        PREVIOUS_TOKEN_KEY,
        String(JwtService.getToken())
      );

      // setTimeout(() => {
        JwtService.saveToken(data.data.user.access_token);
        JwtService.setUserId(data.data.user.id);
        UserInfo.saveUser(data.data.user);
        window.open("/bot-connections");
      // }, 200);

    })
    .finally(async() => {
      wait = false

    })
  }
};

export const getPreviousToken = () => {
  return window.localStorage.getItem(PREVIOUS_TOKEN_KEY);
};

export const exitAutoLogin = () => {
  // set admin-user info
  const prev_user_info = JSON.parse(
    String(window.localStorage.getItem(PREVIOUS_USER_INFO))
  );

  if (!prev_user_info) {
    return;
  } else {
    userInfo.saveUser(prev_user_info);

    // set user id
    jwtService.setUserId(prev_user_info?.id);

    // set user token
    const token = String(getPreviousToken());
    JwtService.saveToken(token);

    // remove previous
    window.localStorage.removeItem(PREVIOUS_TOKEN_KEY);
    window.localStorage.removeItem(PREVIOUS_USER_INFO);

    setTimeout(() => {
      window.close();
    }, 1000);
  }
};

export default { autoLogin, checkAutoLogin, getPreviousToken, exitAutoLogin };
