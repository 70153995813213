import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fs-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.value !== ""
      ? _ctx.translate(_ctx.value)
      : _ctx.user?.country?.currency_code
      ? _ctx.translate(_ctx.user?.country?.currency_code)
      : ""), 1))
}