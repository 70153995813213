import { createI18n } from "vue-i18n/index";

const messages = {
  fa: {
    Inboxino: "اینباکسینو",
    dashboard: "داشبورد",
    layoutBuilder: "صفحه ساز",
    builder: "سازنده",
    onlineQuestionnaire: "پرسشنامه آنلاین",
    services: "خدمات",
    replied: "پاسخ داده شده",
    others: "سایر",
    error: "خطا",
    copy: "کپی",
    connect: "وصل شو",
    download: "دانلود",
    "download report": "دانلود گزارش",
    close: "بستن",
    minute: "دقیقه",
    done: "انجام شد",
    buy: "خرید",
    training: "آموزش",
    credit: "کیف پول",
    type: "نوع",
    description: "توضیح",
    Notifications: "اعلان‌ها",
    loading: "درحال بارگزاری...",
    reservation: "نوبت دهی",
    closeAll: "بستن همه",
    api: "وب سرویس",
    platform: "پلتفرم",
    Piarino: "پیارینو",
    craft: "صفحات آماده",
    pages: "صفحه‌ها",
    email: "ایمیل",
    profile: "پروفایل",
    support: "پشتیبانی",
    profileOverview: "نمای کلی",
    clicked: "کلیک کنید",
    payment: "ّپرداخت",
    projects: "پروژه‌ها",
    Previous: "قبلی",
    campaigns: "کمپین‌ها",
    documents: "اسناد",
    connections: "اتصالات",
    wizards: "ابزار جادویی",
    horizontal: "افقی",
    vertical: "عمودی",
    account: "حساب",
    accountOverview: "نمای کلی",
    settings: "تنظیمات",
    authentication: "احراز هویت",
    basicFlow: "جریان اساسی",
    signIn: "ورود",
    signUp: "ثبت‌نام",
    signOut: "خروج",
    passwordReset: "بازنشانی رمزعبور",
    error404: "ارور 404",
    error500: "ارور 500",
    apps: "برنامه‌ها",
    chat: "چت",
    privateChat: "چت خصوصی",
    groupChat: "چت گروهی",
    drawerChat: "چت ساز",
    widgets: "ابزارک‌ها",
    widgetsLists: "لیست‌ها",
    widgetsStatistics: "آمارها",
    widgetsCharts: "نمودار‌ها",
    widgetsMixed: "مخلوط",
    widgetsTables: "جدول‌ها",
    widgetsFeeds: "فیدها",
    changelog: "تغییرات",
    docsAndComponents: "مستندات و کامپوننت‌ها",
    megaMenu: "مگا منو",
    exampleLink: "پیوند نمونه",
    example: "نمونه",
    modals: "مودال‌ها",
    general: "عمومی",
    item: "مورد",
    year: "سال",
    month: "ماه",
    week: "هفته",
    tag: "برچسب",
    day: "روز",
    toman: "تومان",
    permissions: "دسترسی ها",

    "bot_list": "لیست ربات ها",
    "This field is required":"این فیلد الزامی است" ,
    "bot_detail": "جزییات ربات",
    "bot_edit": "ویرایش ربات",
    "bot_power_of": "خاموش کردن ربات",
    "bot_delete": "حذف ربات",
    "bot_change_expiry_time": "تغییر زمان انقضای ربات",
    "bot_change_server": "تغییر سرور ربات",
    "bot_change_proxy": "تغییر پروکسی ربات",
    "bot_expiry_list": "لیست انقضا ربات",
    "discount_list": "لیست تخفیف ها",
    "discount_view": "مشاهده تخفیف ها",
    "discount_create": "ایجاد کد تخفیف",
    "discount_update": "به‌روزرسانی کد تخفیف",
    "discount_delete": "حذف کد تخفیف",
    "message_list": "لیست پیام ها",
    "message_detail": "مشاهده جزییات پیام",
    "message_send": "ارسال پیام",
    "notifications_log": "لاگ های نوتیف",
    "notice_list": "لیست اطلاعیه ها",
    "notice_view": "مشاهده اطلاعیه",
    "notice_create": "ایجاد اطلاعیه",
    "notice_update": "به‌روزرسانی اطلاعیه",
    "notice_delete": "حذف اطلاعیه",
    "package_list": "لیست پکیج ها",
    "package_view": "مشاهده ی پکیج ها",
    "package_create": "ایجاد پکیج",
    "package_update": "به‌روزرسانی پکیج ها",
    "package_delete": "حذف پکیج",
    "proxy_list": "لیست پروکسی ها",
    "proxy_refresh": "رفرش کردن پروکسی ها",
    "secretary_list": "لیست پاسخگوی‌خودکار ها",
    "server_list": "لیست سرور ها",
    "server_view": "مشاهده سرور",
    "server_create": "ایجاد سرور",
    "server_update": "به‌روزرسانی سرور ها",
    "server_reboot": "ریبوت کردن سرور",
    "server_clean_profiles": "پاک کردن پروفایل سرور",
    "ticket_list": "لیست تیکت ها",
    "ticket_view": "مشاهه تیکت ها",
    "ticket_reply": "پاسخ دادن به تیکت",
    "ticket_close": "بستن تیکت",
    "transaction_list": "لیست تراکنش ها",
    "transaction_view": "مشاهده ی تراکنش ها",
    "verify_cash_transaction": "تایید تراکنش",
    "user_list": "لیست کاربران",
    "user_detail": "جزییات کاربران",
    "user_create": "ایجاد کاربر",
    "user_edit": "ویرایش کاربر",
    "auto_login": "ورود مستقیم به پنل کابر",
    "create_factor": "ایجاد فاکتور",
    "user_change_permissions": "تغییر دسترسی ها",
    "server_delete": "حذف سرور",
    "permissions-list": "لیست دسترسی ها",
    "permissions_list": "لیست دسترسی ها",
    "admin_list": "لیست ادمین ها",
    "activation_codes": "کد های فعال سازی",
    "agents_list": "لیست درخواست های نمایندگی",
    "create_user": "ایجاد کاربر",
    "show_screenshots": "نمایش اسکرین شات ها",
    "transaction_export": "خروجی اکسل تراکنش ها",
    "user_export": "خروجی اکسل کاربران",
    "bot_export": "خروجی اکسل ربات ها",
    "transactions_log": "لیست تراکنش ها",

    success: "موفق",
    preview: "پیش نمایش",
    visit: "بازدید",
    note: "یادداشت",
    Field: "فیلد",
    Type: "نوع",
    start: "شروع کن",
    Required: "الزامی",
    Description: "توضیحات",
    registration: "ثبت‌نامی",
    person: "نفر",
    Introduced: "معرفی شده‌ها",
    inviteFriends: "دعوت از دوستان",
    viewUsers: "مشاهده کاربران",
    upgradePlan: "ارتقا طرح",
    shareAndEarn: "اشتراک گذاری",
    forms: "فرم‌ها",
    newTarget: "هدف جدید",
    newCard: "کارت جدید",
    newAddress: "آدرس جدید",
    createAPIKey: "ایجاد کلید API",
    twoFactorAuth: "احراز هویت دو مرحله ای",
    createApp: "ایجاد برنامه",
    createAccount: "ایجاد حساب",
    documentation: "اسناد",
    components: "کامپوننت‌ها",
    resources: "منابع",
    uploading: "در حال آپلود",
    activity: "فعالیت",
    customers: "مشتریان",
    gettingStarted: "شروع به کار",
    customersListing: "فهرست مشتریان",
    customerDetails: "جزئیات مشتریان",
    errorDetails: "جزئیات خطا",
    calendarApp: "تقویم",
    time: "زمان",
    date: "تاریخ",
    shortAnswer: "پاسخ کوتاه",
    paragraph: "پاسخ بلند",
    multipleChoice: "چند انتخابی",
    checkBoxes: "چند گزینه ای",
    dropdown: "کشویی",
    fileUpload: "آپلود فایل",
    subscription: "اشتراک",
    'subscription status': "وضعیت اشتراک",
    subscriptions: "اشتراک‌ها",
    subscriptionList: "فهرست اشتراک‌ها",
    addSubscription: "افزودن اشتراک",
    viewSubscription: "مشاهده اشتراک",
    no_subscription: "اشتراک ندارید",
    charge_credit_cash: "شارژ کیف پول",
    expired: "منقضی شده",
    pending: "در انتظار",
    back: "بازگشت",
    stop: "توقف",
    "Need to connect": "نیاز به اتصال",
    ready: "فعال",
    LOGGING_IN :"وارد شده",
    send_code :"کد ارسال شده",
    google: "گوگل",
    friends: "دوستان",
    print: "پرینت",
    'familiarity method': "شیوه‌ی آشنایی",
    linkedin: "لینکدین",
    exhibition: "نمایشگاه",
    other: "موارد دیگر",
    need_login: "نیاز به ورود",
    ready_to_login: "آماده ورود",
    all_done: "انجام شده",
    sending: "درحال ارسال",
    failed: "ناموفق",
    need_pay: "در انتظار پرداخت",
    delay: "متوقف شده",
    deactivate: "غیر فعال",
    explanation: "توضیح",
    active: "فعال",
    reply_user: "پاسخ کاربر",
    reply_operator: "پاسخ اپراتور",
    waiting_to_reply: "در انتظار پاسخ",
    closed: "بسته شده",
    discount: "تخفیف",
    admin_dashboard: "داشبورد مدیریت",
    users: "کاربران",
    users_list: "لیست کاربران",
    packages: "پکیج‌ها",
    tickets: "تیکت‌ها",
    transactions: "لیست تراکنش‌ها",
    transaction: "تراکنش",
    servers: "سرورها",
    discounts: "تخفیف ها",
    management: "مدیریت",
    high: "بالا",
    medium: "متوسط",
    low: "کم",
    cancel: "لغو",
    welcome: "خوش آمدید",
    enjoy: "لذت ببرید",
    telegram: "تلگرام",
    whatsapp: "واتساپ",
    whatsapp_vip : "واتساپ pro",
    'Whatsapp Pro' : "واتساپ pro",
    conversation: "گفتگو",
    answered: "پاسخ داده شد",
    paid: "پرداخت شده",
    sent: "ارسال شد",
    cooperationInSales: "همکاری در فروش",
    amount: "مبلغ",
    percent: "درصد",
    break_time: "تایم استراحت",
    send_count: "تعداد ارسال",
    text: "متن",
    file: "فایل",
    image: "تصویر",
    video: "ویدیو",
    location: "موقعیت مکانی",
    number: "شماره",
    mobile: "موبایل",
    refund :"مرجوع شده",
    phone: "تلفن",
    actions: "اعمال",
    list: "لیست",
    ID: "شناسه",
    end: "اتمام",
    edit: "ویرایش",
    Repurchase: "خرید مجدد",
    delete: "حذف",
    refresh: "بارگزاری",
    newest: "جدیدترین",
    oldest: "قدیمی ترین",
    latest_sends: "آخرین ارسال‌ها",
    to: "برای",
    messages: "پیام",
    Whatsapp: "واتساپ",
    package: "پکیج",
    Amount: "مبلغ",
    Discount: "تخفیف",
    Finalizing: "در حال اتصال پایانی ...",
    user: "کاربر",
    message: "پیام",
    Recipients: "دریافت کنندگان",
    Condition: "وضعیت",
    Report: "گزارش",
    resend: "ارسال مجدد",
    No: "شماره",
    bale: "بله",
    Bale: "بله",
    eitaa: "ایتا",
    Eitaa: "ایتا",
    caption: "متن ارسالی",
    view: "مشاهده",
    display: "نمایش",
    Posted: "ارسال شده",
    numbers: "شماره‌ها",
    manual: "دستی",
    excel: "اکسل",
    phonebook: "دفترچه تلفن",
    variable: "پیام شخصی‌سازی شده",
    radial: "محوری",
    linearScale: "نمایش خطی",
    Here: "اینجا",
    use: "استفاده کنید",
    save: "ذخیره",
    Add: "افزودن",
    yes: "بله",
    no: "خیر",
    Phonebook: "دفترچه تلفن",
    Tag: "برچسب",
    Copy: "کپی",
    title: "عنوان",
    Documentation: "مستندات",
    copied: "کپی شد",
    Update: "بروزرسانی",
    Me: "من",
    send: "ارسال",
    Subsets: "زیر‌مجموعه‌ها",
    Yet: "تاکنون",
    Phone: "تلفن ثابت",
    Ok: "بسیار خب",
    Commission: "پورسانت",
    Percentage: "درصد",
    code: "کد",
    link: "لینک",
    inventory: "موجودی",
    ticket: "تیکت",
    Title: "عنوان",
    Priority: "اولویت",
    Search: "جستجو",
    Status: "وضعیت",
    status: "وضعیت",
    reset: "ریست",
    call: " تماس بگیرید",
    Top: "بالا",
    Pay: "پرداخت",
    Unnecessary: "غیر ضروری",
    Message: "پیام",
    operator: "اپراتور",
    Continuation: "ادامه",
    login: "ورود",
    or: "یا",
    name: "نام",
    gender: "جنسیت",
    man: "مرد",
    woman: "زن",
    optional: "اختیاری",
    register: "ثبت‌نام",
    Information: "اطلاعات",
    History: "تاریخ",
    unpaid: "پرداخت نشده",
    ok: "بسیار خب",
    Autoresponder: "پاسخگوی خودکار",
    off: "خاموش",
    sends: "‌ارسال می‌شود",
    on: "روشن",
    Telegram: "تلگرام",
    Rubika: "روبیکا",
    rubika: "روبیکا",
    platforms: "پلتفرم",
    password: "رمزعبور",
    submit: "ارسال",
    required: "الزامی",
    online: "آنلاین",
    IRT: "تومان",
    me: "خودم",
    create: "ایجاد",
    connecting: "در حال اتصال",
    "has been stopped": "متوقف شده",
    url: "آدرس اینترنتی",
    bulk_message: "پیام انبوه",
    notification: "اعلان",
    bot_stopped: "اشتراک متوقف شد",
    id: "شناسه",
    not_supported: "عدم پشتیبانی",
    commission: "پورسانت",
    Activation: "فعال سازی",
    charge_credit: "شارژ کیف پول",
    "Balance after transaction": "موجودی بعد از تراکنش",
    admin: "ادمین",
    undone: "انجام نشده",
    doing: "درحال انجام",
    completed: "به اتمام رسیده",
    Invoice: "فاکتور",
    postal_code: "کد پستی",
    address: "آدرس",
    city: "شهر",
    total_sent_count: "تعداد کل ارسال شده",
    total_bulk_messages: "کل پیام‌های انبوه",
    total_notifications: "کل اعلان‌ها",
    secretaries: "پاسخگوهای خودکار",
    selling_price: "قیمت فروش",
    selling_count: "تعداد فروش",
    registration_count: "تعداد ثبت‌نام",
    budget: "بودجه",
    likes_count: "تعداد_لایک",
    views_count: "تعداد بازدیدها",
    bots_log: "لاگ‌های ربات",
    customer: "مشتری",
    company: "شرکت",
    de_active: "غیر فعال",
    deactive: "غیر فعال",
    Api: " وبسرویس (API)",
    second: "ثانیه",
    support_history: "تاریخچه پشتیبانی",
    main_information: "اطلاعات اصلی",
    financial: "مالی",
    bots: "ربات‌ها",
    secretary: "پاسخگوی‌خودکار",
    restricted: "محدود شده",
    develop: "توسعه",
    marketing: "بازاریابی",
    social_marketing: "بازاریابی شبکه‌های اجتماعی",
    seo: "سئو",
    google_ads: "تبلیغات گوگل",
    click_advertising: "تبلیغات کلیکی",
    video_base_advertising: "تبلیغات پایه ویدیویی",
    site_advertising: "تبلیغات سایت",
    instagram: "اینستاگرام",
    aparat: "آپارات",
    youtube: "یوتیوب",
    website: "سایت اینترنتی",
    tax: "مالیات بر ارزش افزوده",
    country: "کشور",
    pindo: "پیندو",
    iran_tejarat: "ایران تجارت",
    bama: "باما",
    rahnama: "همشهری",
    eforoosh: "ای‌فروش",
    niazmandyha: "نیازمندی‌ها",
    finished :"به اتمام رسیده",
    "bank No" :"بانک شماره",
    "Autoresponder-messages":"پیام‌های پاسخگوی خودکار",
    "Expiration sending time":"اتمام زمان ارسال/لغو توسط کاربر",
    "Islamic Republic of Iran": "ایران",
    "Select": "انتخاب کنید",
    "way of dating": "شیوه آشنایی",
    "created date": "تاریخ ایجاد",
    "expired date": "تاریخ انقضا",
    SEND_BULK_MESSAGES_RULES: "قوانین ارسال پیام انبوه",
    LAST_REQUEST_TIME: "زمان آخرین درخواست",
    user_buy_package_commission: "پورسانت خرید پکیج توسط کاربر",
    user_buy_package_commission_type: "نوع پورسانت خرید پکیج توسط کاربر",
    user_repurchase_package_commission: "پورسانت پکیج خرید مجدد کاربر",
    user_repurchase_package_commission_type: "نوع پورسانت پکیج خرید مجدد کاربر",
    agent_buy_package_commission: "پورسانت خرید پکیج نماینده",
    agent_buy_package_commission_type: "نوع پورسانت خرید پکیج نماینده",
    agent_repurchase_package_commission: "پورسانت پکیج بازخرید نماینده",
    agent_repurchase_package_commission_type:
      "نوع پورسانت پکیج بازخرید نماینده",
    "change proxy": "تغییر پروکسی",
    "The number to receive system notifications" : "شماره ی دریافت اعلانات سیستم",
    "site address":
      "آدرس سایت",
      "You have entered another user panel" : "شما در پنل کاربر دیگری هستید",
    "Enter the site address":
      "آدرس سایت خود را وارد کنید",
    "Edit send settings": "ویرایش تنظیمات ارسال",
    "Restart the bot servers": "سرور‌های ربات ری‌استارت شود",
    "expiring-bots": "اشتراک‌های در حال انقضا",
    "advanced settings": "تنظیمات پیشرفته",
    "Link With Phone Number": "اتصال با شماره تلفن",
    "Link With QR Code": "اتصال با Qr Code",
    "Invoice and payment": "فاکتور و پرداخت",
    "Print Invoice": "چاپ فاکتور",
    "verify email": "تائید ایمیل",
    "File sent": "فایل ارسال شده",
    "Line Chart": "نمودار خطی",
    "Bar Chart": "نمودار میله ای",
    "change profile avatar": "تغییر عکس پروفایل",
    "Two day free trial": "دو روز تست رایگان",
    "free test subscriptions": "اشتراک تست رایگان",
    "Click the button below to connect": "برای اتصال روی دکمه زیر کلیک کنید",
    "Sends a message": "پیام ارسال می‌کند",
    "in each period": "در هر دوره",
    "create Autoresponder": "ایجاد پاسخگوی خودکار",
    "Autoresponder name": "نام پاسخگوی خودکار",
    "Every time we": "در هر باری که ما",
    "Activate Autoresponder": "فعال سازی پاسخگوی خودکار",
    "Turn off the Autoresponder": "خاموش کردن پاسخگوی خودکار",
    "change password": "تغییر رمزعبور",
    "Introducing the services": "معرفی خدمات",
    "Buy a subscription": "خرید اشتراک",
    "Your connection to the socket was terminated": "اتصال شما به سوکت قطع شد",
    "Active subscriptions": "اشتراک‌های فعال",
    "Total visit": "کل بازدید",
    "Wallet balance": "موجودی کیف پول",
    "User wallet balance": "موجودی کیف پول کاربر",
    "pay attention": "توجه کنید!",
    "file not uploaded": "فایل آپلود نشد",
    "phone book": "دفترچه تلفن",
    "Help and training": "راهنما و آموزش",
    "Terms and Conditions": "قوانین و مقررات",
    "bulk-message": "پیام انبوه",
    "buy a package": "خرید پکیج",
    "Recharge wallet": "شارژ کیف پول",
    "Withdraw from the wallet": "برداشت از کیف پول",
    "From the purchase of referred users": "از خرید کاربران معرفی شده",
    "Introduced users": "کاربران معرفی شده",
    "Invoice to": "فاکتور برای",
    "Registered users with your introduction": "کاربران ثبت‌نامی با معرفی شما",
    "Total purchases of introduced users": "جمع خرید کاربران معرفی شده",
    "Notification is not available": "اعلانی موجود نمی‌باشد",
    "Bulk messages sent": "پیام‌ انبوه ارسال شده",
    "active Autoresponder": "پاسخگوی خودکار فعال",
    "Number of": "شماره از",
    "is the number": "شماره است",
    "Users with non-free packages": "کاربران دارای پکیج غیر رایگان",
    "user of": "کاربر از",
    "is the user": "کاربر است",
    "my profile": "پروفایل من",
    "contact-us": "تماس با ما",
    "Choose the features you need": "امکاناتیو که لازم داری انتخاب کن",
    "Package selection and payment": "انتخاب پکیج و پرداخت",
    "Start enjoying": "شروع کن لذت ببر",
    "to exit": "برای خروج",
    "User activity": "فعالیت کابران",
    "potential-customer": "مشتریان بالقوه",
    "unique visit": "بازدید یونیک",
    "all visit": "کل بازدید",
    "Total amount of successful transactions": "جمع مبلغ تراکنش‌ها موفق",
    "Total amount of failed transactions": "جمع مبلغ تراکنش‌ها ناموفق",
    "Status chart": "چارت وضعیت",
    "new subscription": " اشتراک جدید",
    "sum amount": "جمع مبلغ",
    "Total purchase": "کل خرید",
    "Total commission": "کل پورسانت",
    "Sales cooperation chart": "چارت همکاری در فروش",
    "agency-requests": "درخواست‌های نمایندگی",
    "subscription management": "مدیریت اشتراک‌ها",
    "My subscriptions list": "لیست اشتراک‌های من",
    "Teaching how to use the Inboxino panel": "آموزش استفاده از پنل اینباکسینو",
    "Teaching bulk messaging": "آموزش ارسال پیام انبوه",
    "Teaching web service connection": "آموزش اتصال وب سرویس",
    "Autoresponder training": "آموزش پاسخگوی خودکار",
    "Training cooperation in sales": "آموزش اتصال شماره و خرید اشتراک",
    "Rules for sending messages": "آموزش همکاری در فروش",
    "Use default settings": "استفاده از تنظیمات پیش‌فرض",
    "Purchase and renewal of subscription": "خرید و تمدید اشتراک",
    "Purchase and subscription renewal training": "آموزش خرید و تمدید اشتراک",
    "The type of operation": " نوع عملیات",
    "Renewal of package no": "تمدید پکیج شماره",
    "subscriptions number": "شماره اشتراک",
    "Enter the  number": "شماره را وارد کنید",
    "Choosing the type of packages": "انتخاب نوع پکیج‌ها",
    "Choose the duration of the package": "انتخاب مدت زمان پکیج",
    "Features of the plan": "ویژگی‌های پلن",
    "This plan is up to date": "این پلن تا تاریخ",
    "Turn off the secretary": "خاموش کردن پاسخگوی‌خودکار",
    "is active": "فعال می‌باشد.",
    "I have a discount code": "کد تخفیف دارم",
    "Enter the discount code": "کد تخفیف را وارد کنید",
    "The discount code is valid": "کد تخفیف معتبر می‌باشد",
    "The discount code is not valid": "کد تخفیف معتبر نمی‌باشد",
    "Payment info": " اطلاعات پرداخت",
    "Amount payable": "مبلغ قابل پرداخت",
    "Payment Method": "روش پرداخت",
    "If you need help": "در صورت نیاز به راهنمایی",
    "click here": "اینجا کلیک کنید",
    "online payment": "پرداخت آنلاین",
    "Payment through the bank portal": "پرداخت از طریق درگاه بانکی",
    "Card by card": "کارت به کارت",
    "Payment by card to card": "پرداخت به روش کارت به کارت",
    "please wait": "لطفا منتظر بمانید ...",
    "Payment and activation": "پرداخت و فعال سازی",
    "Transaction registration": "ثبت تراکنش",
    "Full package of features": "پکیج کامل امکانات",
    "Select the desired package": "پکیج مورد نظر را انتخاب کنید",
    "Upload the transaction receipt": "رسید تراکنش را آپلود کنید.",
    "Error in transfer to bank portal": "خطا در انتقال به درگاه بانکی",
    "Error in cost calculation": "خطا در محاسبه هزینه",
    "Log-out": "قطع ارتباط",
    "Error in deleting subscription": "خطا در حذف ربات متصل",
    "Connection done": "اتصال انجام شد",
    "Sending bulk messages": "ارسال پیام انبوه",
    "please be patient": "لطفا صبور باشید.",
    "Guide to connect to": "راهنمای اتصال به",
    "Menu option": "گزینه‌ی منو",
    "or settings": "یا تنظیمات",
    "Select the LINK A DEVICE option": "گزینه‌ی LINK A DEVICE را انتخاب کنید",
    "Video guide to connect": "راهنمایی ویدئویی اتصال",
    "Scan the qrcode (image above)": "qrcode (تصویر بالا) را اسکن کنید",
    "Connect up": "اتصال تا",
    "It will be established in another second": "ثانیه دیگر برقرار می‌گردد.",
    "Choose a package": "انتخاب پکیج",
    "Bulk message settings": "تنظیمات پیام انبوه",
    "Number of submissions per session": "تعداد ارسال در هر جلسه",
    "Sending time between each session": "زمان ارسال بین هر جلسه",
    "Save changes": "ذخیره تغییرات",
    "The profile has been updated": "پروفایل آپدیت شد",
    "Error saving data": "خطا در ذخیره اطلاعات",
    "commission sum": "مبلغ پورسانت",
    "Wallet charge amount": "مبلغ شارژ کیف پول",
    "Transaction amount": "مبلغ تراکنش",
    "Enter the transaction amount": "مبلغ تراکنش را وارد کنید",
    "Transaction date": "تاریخ تراکنش",
    "Select the transaction date": "تاریخ تراکنش را انتخاب کنید",
    "Upload the payment receipt": "آپلود رسید پرداخت",
    "Upload your receipt": "رسید خود را آپلود کنید",
    "send file": "ارسال فایل",
    "Select the receipt file": "انتخاب فایل رسید",
    "Send to server": "ارسال به سرور",
    "Please enter your file": "لطفا فایل خود را وارد کنید",
    "Issue Tracking": "شماره پیگیری",
    "Enter the tracking number": "شماره پیگیری را وارد کنید",
    "card number": "شماره کارت",
    "Shaba number": "شماره شبا",
    "Messages sent successfully": "پیام‌های ارسال شده موفق",
    "Messages sent failed": "پیام‌های ارسال شده ناموفق",
    "Bulk message (advertising)": "پیام انبوه (تبلیغاتی)",
    "Sending bulk messages (advertisements)": "ارسال پیام انبوه (تبلیغاتی)",
    "How not to block?": "چطور بلاک نشیم ؟",
    "Start time of sending": "ساعت شروع ارسال",
    "Start date of sending": "تاریخ شروع ارسال",
    "End date of sending": "تاریخ پایان ارسال",
    "Stop sending time": "ساعت توقف ارسال",
    "end of sending": "پایان ارسال",
    "the operation": "عملیات",
    "Start sending messages": "شروع ارسال پیام‌ها",
    "stop sending": "توقف ارسال",
    "Error changing message status": "خطا در تغییر وضعیت پیام",
    "text message": "پیام متنی",
    "Bulk message report sent": "گزارش پیام انبوه ارسال شده",
    "start time": "ساعت شروع",
    "end time": "ساعت پایان",
    "message recipients": "دریافت کنندگان پیام",
    "Update report": "به روز رسانی گزارش",
    "send queue": "صف ارسال",
    "Cancel send": "لغو ارسال",
    "Error loading message information": "خطا در بارگزاری اطلاعات پیام",
    "Error canceling message sending": "خطا در لغو ارسال پیام",
    "Error resending message": "خطا در باز ارسال پیام",
    "Choose a subscription": "انتخاب اشتراک",
    "How to enter numbers": "روش ورود شماره‌ها",
    "Select Excel file": "انتخاب فایل اکسل",
    "Download the sample file": "دانلود فایل نمونه",
    "rest time": "زمان استراحت",
    "How many messages should be sent daily?": "روزانه چند پیام ارسال شود ؟",
    "Rest time between each period": "زمان استراحت بین هر دوره",
    "Enter your message": "پیام خود را وارد کنید",
    "Enter the title of your message" : "عنوان پیام خود را وارد کنید",
    "The title of the message is displayed only for you": "عنوان پیام تنها برای شما نمایش داده میشود",
    "title is required" :"عنوان اجباری می‌باشد",
    "select file": "انتخاب فایل",
    "select country code": "انتخاب کد کشور",
    "Send as a file": "ارسال به صورت فایل",
    "I agree with the rules": "با قوانین موافقم",
    "View the rules": "مشاهده قوانین",
    "end sending": "پایان ارسال",
    "Try again": "تلاش دوباره",
    "Error sending mass message": "خطا در ارسال پیام انبوه",
    "You have not registered a phone book": "دفترچه تلفنی ثبت نکرده اید",
    "You do not have a subscription": "اشتراک ندارید",
    "To view the guide": "برای مشاهده راهنما",
    "Create Autoresponder": "ایجاد پاسخگوی خودکار",
    "Autoresponder status": "وضعیت پاسخگوی خودکار",
    "menu number": "تعداد منو",
    "Turn off all Autoresponder ": "خاموش کردن پاسخگوهای خودکار",
    "Horizontal view": "نمایش افقی",
    "Collapse|Expand menu": "جمع - باز کردن منو",
    "Hide settings": "مخفی کردن تنظیمات",
    "new Autoresponder ": " پاسخگوهای خودکار جدید",
    "edit name": "ویرایش نام",
    "Message format": "قالب پیام‌ها",
    "To add the current time from": "جهت افزودن زمان جاری از",
    "And to add the current date from": "و جهت افزودن تاریخ جاری از",
    "Welcome message": "پیام خوش آمدگویی",
    "Start creating a menu": "شروع ایجاد منو",
    "Click to test for free": "کلیک کن تا رایگان تست کنی",
    "Error loading menus": "خطا در بارگزاری منوها",
    "Are you sure to delete this menu?": "آیا از حذف این منو اطمینان دارید؟",
    "Error in creating menu": "خطا در ایجاد منو",
    "mission accomplished": "عملیات با موفقیت انجام شد.",
    "Error editing settings": "خطا در ویرایش تنظیمات",
    "Error in operation": "خطا در انجام عملیات",
    "Platform connection status": "وضعیت اتصال پلتفرم",
    "menu title": "عنوان منو",
    "Number or sent option": "عدد یا گزینه ارسالی",
    "I will log in again": "دوباره وارد میشم",
    "Please log in again": "لطفا مجددا وارد سیستم شوید",
    "Error code": "کد ارور",
    "PAYMENT DETAILS": "جزئیات پرداخت",
    "discount code": "کد تخفیف",
    "Internet connection established": "اتصال اینترنت برقرار شد",
    "Online chat with support": "چت آنلاین با پشتیبان",
    "You have received a new message": "پیام جدید دریافت کرده اید",
    "online support": "پشتیبانی آنلاین",
    "ask me": "از من بپرس",
    "New message": "پیام جدید",
    "Starts sending": "شروع می‌شود.",
    "Sending messages at": "ارسال پیام هر روز در ساعت ",
    "stoped every day and next day at": "متوقف می‌شود و روز بعد در ساعت ",
    "started again": " مجددا شروع می‌شود.",

    "In every us attempt to send": "در هر تلاش ما برای ارسال",
    "Then every time we tries to send": "بعد از ارسال هر",
    "we every day only": "روزانه حداکثر",
    "messages are sent": "پیام ارسال می‌شود",
    "Download all": "دانلود همه",
    "Download sent messages": "دانلود پیام‌های ارسال شده",
    "Download unsent messages": "دانلود پیام‌های ارسال نشده",
    "Download sending messages": "دانلود پیام‌های درحال ارسال",
    "In column A: Enter your numbers": "در ستون A : شماره‌های خود را وارد کنید",
    "Pay with Credit": "پرداخت از کیف پول",
    "Welcome back": "خوش آمدی",
    "web-hook": "وب هوک",
    "applicable cases": "موارد کاربرد",
    "create webhook": "ایجاد وب هوک",
    "Select options": "انتخاب گزینه‌ها",
    "Messages sent with the web service": "پیام‌های ارسال شده با وب سرویس",
    "Text or number example 5": "متن یا عدد مثال 5",
    "Message text": "متن پیام",
    "send image": "ارسال تصویر",
    "send video": "ارسال ویدیو",
    "send audio": "ارسال صوت",
    "Delete message": "حذف پیام",
    "edit title": "ویرایش عنوان",
    "Calling word welcome message": "کلمه فراخوانی پیام خوش‌آمد‌گویی",
    "Add contacts to the phone book": "افزودن مخاطبین به دفترچه تلفن",
    "Select phone book": "انتخاب دفترچه تلفن",
    "Create Menu": "ایجاد منو",
    "Edit Menu": "ویرایش منو",
    "View the file": "مشاهده فایل",
    "Select the desired number": "انتخاب شماره مورد نظر",
    "Autoresponder successfully copied": "پاسخگوی خودکار با موفقیت کپی شد",
    "Audience message": "پیام مخاطب",
    "Add submenu": "افزودن زیر منو",
    "Web service (connection to the site)": "وب سرویس (اتصال به سایت)",
    "Web service training": "آموزش وب سرویس",
    "Create a web service": " ایجاد وب سرویس",
    "Connected to subscription": "متصل به اشتراک",
    "Error deleting api!": "خطا در ایجاد وب سرویس",
    "Service name": "نام سرویس",
    "Enter the service name": "نام سرویس را وارد کنید",
    "Service information": "اطلاعات سرویس",
    "َApi Key": "کلید وب سرویس",
    "Please enter the name of the service": "لطفا نام سرویس را وارد کنید",
    "Error creating api": "خطا در ایجاد وب سرویس",
    "All my whatsapp": "همه واستاپ‌های من",
    "Error loading messages": "خطا در بارگزاری پیام‌ها",
    "You have attracted a subset": "زیر مجموعه جذب کرده اید.",
    "total payment": "مجموع پرداخت",
    "Commission percentage": "درصد پورسانت",
    "My commission": "پورسانت من",
    "You have not introduced a user yet": "شما تاکنون کاربری معرفی نکرده اید.",
    "Cooperation in the sale of Inboxino?": "همکاری در فروش اینباکسینو ؟",
    "Frequently asked questions": "سوالات پر تکرار",
    "How do I advertise?": "به چه صورت تبلیغات انجام دهم؟",
    "Soon ....": "به زودی ....",
    "How is profit calculated?": "نحوه محاسبه سود به چه صورت می‌باشد ؟",
    "join us": "به ما بپیوندید",
    "first name and last name": "نام و نام خانوادگی",
    "phone number": "شماره موبایل",
    "National Code": "کد ملی",
    "Your Career": "شغل شما",
    "Why did you choose Inboxino?": "چرا اینباکسینو را انتخاب کردید؟",
    "Registration of representation request": "ثبت درخواست نمایندگی",
    "Purchase amount": "مبلغ خرید",
    "Withdrawal requests": "درخواست‌های برداشت",
    "My introduction code and link": "کد و لینک معرفی من",
    "My commission information": "اطلاعات پورسانت‌های من",
    "withdrawal request": "درخواست برداشت",
    "Wallet withdrawal request": "درخواست برداشت از کیف پول",
    "Please verify your email address": "لطفا آدرس ایمیل خود را تائید کنید",
    "Complete your account information": "اطلاعات حساب خود را تکمیل کنید.",
    "Enter the withdrawal amount": "مبلغ برداشت را وارد کنید",
    "Withdrawal balance": "موجودی قابل برداشت",
    "Your card number": "شماره کارت شما",
    "Your Sheba number": "شماره شبا شما",
    "Request registration": "ثبت درخواست",
    "Your wallet balance": "موجودی کیف پول شما",
    "Create ticket": "ایجاد تیکت",
    "The relevant section": "بخش مربوطه",
    "Filter options": "گزینه‌های فیلتر",
    "technical support": "پشتیبانی فنی",
    "financial department": "امور مالی",
    "Submit a support ticket": "ارسال تیکت پشتیبانی",
    "Ticket title": "عنوان تیکت",
    "Enter the title of the ticket": "عنوان تیکت را وارد کنید",
    "Please enter the ticket title": "لطفا عنوان تیکت را وارد کنید",
    "Please select your ticket priority": "لطفا اولویت تیکت را انتخاب کنید",
    "Please select the relevant section": "لطفا بخش مربوطه را انتخاب کنید",
    "Please enter message": "لطفا پیام را وارد کنید",
    "Error creating ticket": "خطا در ایجاد تیکت",
    "Normal user": "کاربر عادی",
    "Ticket number": "شماره تیکت",
    "Related section": "بخش مربوط",
    "Priority ticket": "الویت تیکت",
    "the date of creation": "تاریخ ایجاد",
    "last update": "اخرین بروزرسانی",
    "Error sending ticket response": "خطا در ارسال پاسخ تیکت",
    "Error loading ticket information": "خطا در بارگزاری اطلاعات تیکت",
    "Login to Inboxino": "ورود به اینباکسینو",
    "Verification code": "کد تائید",
    "You with no": "شما با شماره",
    "You are logging in": "در حال ورود به سیستم هستید.",
    "Change no": "تغییر شماره",
    "Register in Inboxino": "ثبت‌نام در اینباکسینو",
    "last name": "نام خانوادگی",
    "Province of residence": "استان محل سکونت",
    "I live in foreign countries": "ساکن کشور‌های خارجی هستم",
    "Identification code": "کد معرف",
    "Create a new notebook": "ایجاد دفترچه جدید",
    "The title of the notebook": "عنوان دفترچه",
    "Number of numbers": "تعداد شماره‌ها",
    "Number management": "مدیریت شماره‌ها",
    "Download Excel": "دانلود اکسل شماره‌ها",
    "Error deleting phonebook": "خطا در حذف دفترچه تلفن",
    "Enter the notebook name": "نام دفترچه را وارد کنید",
    "Please enter the notebook name": "لطفا نام دفترچه را وارد کنید",
    "Error creating phonebook": "خطا در ایجاد دفترچه تلفن",
    "Add number": "اضافه کردن شماره",
    "List of available numbers": "لیست شماره‌های موجود",
    "Remove the number": "حذف شماره",
    "Error loading phone book numbers": "خطا در بارگزاری شماره‌های دفترچه تلفن",
    "Error deleting number": "خطا در حذف شماره",
    "Error in registering numbers": "خطا در ثبت شماره‌ها",
    "There is no data": "داده ای وجود ندارد.",
    "List of transactions": "لیست تراکنش‌ها",
    "Tracking Code": "کد رهگیری",
    "Successful - paid": "موفق - پرداخت شده",
    "Erroneous transaction": "تراکنش خطادار",
    "Training to send bulk messages": "آموزش ارسال پیام انبوه",
    "Cooperation training in sales": "آموزش همکاری در فروش",
    "Rules of sending messages": "قوانین ارسال پیام",
    "my wallet": "کیف پول من",
    "edit profile": "ویرایش پروفایل",
    "Administrative settings": " تنظیمات مدیریتی",
    "My wallet changes": "تغییرات کیف پول من",
    "Profile details": "جزئیات پروفایل",
    "full name": "نام کامل",
    "Authorized files": "فایل‌های مجاز",
    "First name": "نام کوچک",
    "change number": "تغییر شماره",
    "Profile updated": "پروفایل آپدیت شد",
    "The mobile number has been updated": "شماره موبایل آپدیت شد",
    "Error in number change": "خطا در تغییر شماره",
    "Administrative settings saved": "تنظیمات مدیریتی ذخیره شد.",
    "Introduction of Inboxino services": "معرفی خدمات اینباکسینو",
    "Send bulk messages": "ارسال پیام انبوه",
    "Multi-user": "چند کاربره ",
    "Greetings and Regards": "با سلام و احترام",
    "Welcome to Inboxino": "به اینباکسینو خوش آمدید",
    "Introducing Inboxino": "معرفی اینباکسینو",
    "Get Inboxino training videos": "دریافت ویدیوهای آموزشی اینباکسینو",
    "View tariffs": "مشاهده تعرفه‌ها",
    "Address and contact number": "آدرس و شماره تماس",
    "And this is because of the feedback": "و این به دلیل بازخورد است",
    "Enter and save": "وارد و ذخیره کنید",
    "Creating queuing systems in WhatsApp": "ایجاد سیستم‌های صف در واتساپ",
    "Create an online": "ایجاد آنلاین",
    "Ability to Send bulk messages:": "قابلیت ارسال پیام‌های گروهی:",
    "Create Subscription": "ایجاد اشتراک",
    "Connect Messengers": "اتصال پیام‌رسان‌ها",
    "Enjoy Inboxino": "از اینباکسینو لذت ببرید",
    "Send Timer": "ارسال زمان دار",
    "click to select file": "برای انتخاب فایل کلیک کنید",
    "Add new message": "افزودن پیام جدید",
    "advertise campaigns": "کمپین‌های تبلیغاتی",
    "Potential customer": "مشتری بالقوه",
    "Add recipient": "افزودن گیرنده",
    "How many messages are sent ”per day”?": "”در روز” چند پیام ارسال می‌شود ؟",
    "On which platforms is it sent?": "در چه پلتفرم‌هایی ارسال انجام می‌شود ؟",
    "Creating a free subscription": "در حال ساخت اشتراک رایگان",
    "How does Inboxino work ?": "اینباکسینو، چگونه کار می‌کند؟",
    "I will test later": "بعدا تست می‌کنم",
    "Free Subscription": "اشتراک رایگان",
    "Platform Connection": "اتصال پلتفرم",
    "Connect Platforms": "پلتفرم‌ها را متصل کنید",
    "Setting Bulk Message": "تنظیم پیام انبوه",
    "Enter your bulk message": "پیام انبوه خود را وارد کنید",
    "View panel": "مشاهده پنل",
    "Connect one of the platforms": "یکی از پلتفرم‌ها را متصل کنید.",
    "Platform selection is required.": "انتخاب پلتفرم الزامی است.",
    "Choose a platform.": "یک پلتفرم انتخاب کنید.",
    "Enter the numbers.": "شماره‌ها را وارد کنید.",
    "The number of numbers exceeds the limit.": "تعداد شماره بیش از حد مجاز.",
    "Message is required.": "پیام الزامی است.",
    "Enter your message.": "پیام خود را وارد کنید.",
    "I want an official invoice": "فاکتور رسمی می‌خواهم",
    "View invoice and payment": "مشاهده فاکتور و پرداخت",
    "Subscription activation details": "جزئیات فعال سازی اشتراک",
    "User Choice": "انتخاب کاربر",
    "User search and selection": "جستجو و انتخاب کاربر",
    "Searching...": "در حال جستجو ...",
    "Select user subscription": "انتخاب اشتراک کاربر",
    "User purchase link": "لینک خرید کاربر",
    "Create a purchase link": "ایجاد لینک خرید",
    "Daily delivery": "ارسال روزانه",
    "Resolutions and rules of use": "راهکار‌ها و قوانین استفاده",
    "Web Hook documentation": "مستندات وب هووک",
    "Sending Notifications": "ارسال نوتیفیکیشن",
    "Notification Sending Documentation": "مستندات ارسال ",
    "Web Service Information": "اطلاعات وب سرویس",
    "Sample JSON Code": "نمونه کد json",
    "Sample Values": "نمونه مقادیر",
    "Parameter Specifications": "مشخصات پارامترها",
    "Notification Sample Codes": "نمونه کدهای نوتیفیکیشن",
    "File Sending": "ارسال فایل",
    "Sample JSON Code for File Sending": "نمونه کد json ارسال فایل",
    "Retrieve Sent Messages List": "دریافت لیست پیام‌های ارسال شده",
    "Hello, welcome to Inboxino": "سلام، به اینباکسینو خوش آمدید",
    "Your message text": "متن پیام شما",
    "For sorting data": "برای مرتب کردم داده‌ها",
    "For pagination": "برای صفحه بندی",
    "Web service information": "اطلاعات وب سرویس",
    "Retrieve details of sent messages": "دریافت جزئیات پیام‌های ارسال شده",
    "Black List": "لیست سیاه",
    "Type your 4 digit security code": "کد امنیتی 4 رقمی خود را تایپ کنید",
    "Fast, Efficient and Productive": "سریع، کارآمد و سازنده",
    "Enter Phone Or Email": "تلفن یا ایمیل را وارد کنید",
    "Forgot Password ?": "رمزعبور را فراموش کرده اید؟",
    "Enter password": "رمزعبور را وارد کنید",
    "new password": "رمزعبور جدید",
    "current password": "رمزعبور فعلی",
    "Receive verification code": "دریافت کد تائید",
    "Select country": "انتخاب کشور",
    "Login with SMS code": "ورود با کد",
    "with Password": "ورود با رمزعبور",
    "My subscriptions": "اشتراک‌های من",
    "connection tutorial": "آموزش اتصال",
    "Connect to Telegram": "اتصال به تلگرام",
    "Connect to Bale": "اتصال به بله",
    "Connect to Eitaa": "اتصال به ایتا",
    "Connect to Whatsapp": "اتصال به واتساپ",
    "Connect to Rubika": "اتصال به روبیکا",
    "Connect to Whatsapp_vip": "اتصال به واتساپ pro",
    "Autoresponder successfully created": "پاسخگوی خودکار با موفقیت ایجاد شد",
    "Enter the security code": "کد امنیتی را وارد کنید",
    "Choice of platforms": "انتخاب پلتفرم‌ها",
    "please, enter mobile number": "لطفا شماره موبایل را وارد کنید.",
    "Enter the password": "رمزعبور را وارد کنید",
    "Connecting the platform number to the panel": "اتصال شماره پلتفرم به پنل",
    "Activation code sent successfully": "کد فعال سازی با موفقیت ارسال شد",
    "The connection was made successfully": "اتصال با موفقیت انجام شد",
    "name or number": "نام یا شماره",
    "You have not received a commission yet": "هنوز کمیسیونی دریافت نکرده اید",
    "confirm password": "رمزعبور را تایید کنید",
    "Passwords must match": "رمزعبور‌ها باید مطابقت داشته باشند",
    "platform management": "مدیریت پلتفرم‌ها",
    "select platform": "انتخاب پلتفرم",
    "Platform selection is required": "انتخاب پلتفرم الزامی می‌باشد",
    "subscription selection is required": "انتخاب ربات الزامی می‌باشد",
    "Submitted entries are invalid": "ورودی‌های ارسالی نامعتبر است",
    "Error in disconnecting": "قطع ارتباط با مشکلی مواجه شد",
    "Definition of Autoresponder menus": "تعریف منو‌های پاسخگوی خودکار",
    "Management of Autoresponder": "مدیریت پاسخگوهای خودکار",
    "Free test": "تست رایگان",
    "log out": "قطع ارتباط",
    "package support": "پشتیبانی پکیج",
    "to be sent in the future": "در آینده ارسال شود",
    "reason for stopping": "دلیل توقف",
    "Number of messages": "تعداد پیام‌ها",
    "No connected platform found": "پلتفرم متصل یافت نشد",
    "Three days free subscription": "سه روز اشتراک رایگان",
    "message ID": "شناسه پیام",
    "subscription title": "عنوان اشتراک",
    "Enter a name for your subscription": "یک نام برای اشتراک خود وارد کنید",
    "Are you online ?": "آیا شما آنلاین هستید ؟",
    "Email connection successfully completed": "اتصال ایمیل با موفقیت انجام شد",
    "Tap on link with phone number instead and enter this code on your phone":
      "بر روی گزینه link with phone number instead ضربه بزنید و این کد را در تلفن خود وارد کنید",
    "None of your platforms (messengers) are connected to the system":
      "هیچ کدام از پلتفرم‌های (پیام‌رسان) شما به سامانه متصل نیست",
    "Enter the authentication code sent to Email":
      "کد احراز هویت ارسال شده به ایمیل را وارد کنید",
    "Enter the title of Autoresponder menu":
      "عنوان پاسخگوی خودکار را وارد کنید",
    "Received messages from Autoresponder ":
      "پیام‌های دریافتی توسط پاسخگوی خودکار",
    "Enter the number registered in Telegram":
      "شماره ثبت شده در تلگرام را وارد کنید",
    "Enter the authentication code sent by Telegram":
      "کد ارسال شده توسط تلگرام را وارد کنید",
    "Enter the authentication code sent by Bale":
      "کد ارسال شده توسط بله را وارد کنید",
    "Enter the mobile number registered in Telegram":
      "شماره موبایل ثبت‌نام شده در تلگرام را وارد کنید",
    "Enter the authentication code sent by Eitaa":
      "کد ارسال شده توسط ایتا را وارد کنید",
    "Please enter the requested information":
      "لطفا اطلاعات خواسته شده را وارد کنید",
    "Your account is protected by an additional password":
      "حساب شما با یک رمزعبور اضافی محافظت می‌شود",
    "please confirm your country code and enter your phone number":
      "لطفا کد کشور خود را انتخاب کرده و شماره تلفن خود را وارد کنید",
      "enter your phone number" : "شماره تلفن خود را وارد کنید",
    "Unfortunately there was a problem in sending the confirmation code by Telegram":
      "متاسفانه در ارسال کد تائید توسط تلگرام مشکلی رخ داده است",
    "Unfortunately the confirmation code has not been sent":
      "متاسفانه کد تائید ارسال نشده است",
    "Enter your phone number to reset your password":
      "برای بازنشانی رمزعبور شماره موبایل خود را وارد کنید",
    "No withdrawal request has been registered yet":
      "در خواستی برداشتی تاکنون ثبت نشده است.",
    "The message field must contain a value":
      "فیلد پیام‌ها باید حاوی مقداری باشند",
    "Select the platform or platforms with which you want to use Inboxino services":
      "پلتفرم یا پلتفرم‌هایی که با استفاده از آن می‌خواهید از سرویس‌های اینباکسینو استفاده کنید را انتخاب کنید",
    "The desired platform is not supported by the purchased subscription":
      "پلتفرم مورد نظر، توسط اشتراک خریداری شده پشتیبانی نمی‌شود",
    "The desired subscription does not support the autoresponder service":
      "اشتراک مورد نظر سرویس پاسخگوی خودکار را پشتیبانی نمی‌کند",
    "Add the country code to the first numbers":
      "کد کشور را به اول شماره‌ها اضافه کن",
    "Assistant to internal and external messengers":
      "پلتفرم ارسال پیام انبوه و پاسخگوی خودکار",
    "Advertising, sales, information and support":
      "تبلیغات، فروش، اطلاع رسانی و پشتیبانی",
    "Messenger marketing platform":
      "پلتفرم مسنجر مارکتینگ",
    "Messenger marketing platform (bulk message sending, autoresponder and web service in messengers)":
      "پلتفرم مسنجر مارکتینگ (ارسال پیام انبوه، پاسخگوی‌خودکار و وب سرویس در پیام‌رسان‌ها)",
    "The date you want your messages to start sending, for example, if you choose a date in three days, your messages will start sending in three days":
      "تاریخی که می‌خواهید پیام‌های شما شروع به ارسال کند برای مثال اگه تاریخ سه روز دیگر را انتخاب کنید پیام‌های شما سه روز دیگر شروع به ارسال می‌شوند",
    "The time you want your messages to be sent every day, for example you want your messages to be sent every day at 8:00 am":
      "ساعتی که می‌خواهید هر روز پیام‌های شما ارسال شود برای مثال شما می‌خواهید هر روز از ساعت 8 صبح پیام‌های شما ارسال شود",
    "When you no longer want your messages to be sent For example if you dont want a message to be sent to the user after 23":
      "ساعتی که پیام‌های شما دیگر نمی‌خواهید ارسال شود. برای مثال اگر شما نمی‌خواهید ساعت 23 به بعد برای کاربر پیامی ارسال شود",
    "Each time we tries to send a message":
      "در هر باری که ما تلاش برای ارسال پیام می‌کنیم",
    "Users made purchases with your introduction":
      "کاربرانی که با معرفی شما خرید کردند",
    "now you can use and enjoy all Inboxino services easily":
      " اکنون می‌توانید از تمام خدمات اینباکسینو به راحتی استفاده کنید و لذت ببرید",
    "It rests for a minute and then starts sending again":
      "دقیقه استراحت می‌کند و سپس دوباره شروع به ارسال می‌کند",
    "and then starts sending again":
      " و سپس دوباره شروع به ارسال می‌کند",

    "Note that the lower this number the less likely the messenger will block you":
      "توجه داشته باشید که هر چه این عدد بیشتر باشد، احتمال اینکه پیام‌رسان شما را مسدود کند کمتر است",
    "With this option you specify the number of messages sent during the start and end of sending messages in a day and night The remaining messages will be sent the next day":
      "تعداد کمتر پیام در 24 ساعت، احتمال بلاک شدن را کاهش میدهد. برای ارسال به کسانی که شماره شما را در مخاطبین خود ذخیره دارند، محدودیتی وجود ندارد.",
    "To use Autoresponder, create your menus using the button below and then activate your Autoresponder and enjoy as easily as possible":
      "برای استفاده از پاسخگوی خودکار، منوهای خود را با استفاده از دکمه زیر ایجاد کنید و سپس پاسخگوی خودکار خود را فعال کنید و به همین راحتی لذت ببرید",
    "Unfortunately you cannot use Inboxino until you have an active platform":
      "متاسفانه تا زمانی که پلتفرم فعالی نداشته باشید نمی‌توانید از امکانات اینباکسینو استفاده کنید",
    "A file is uploading Please wait until the file is uploaded":
      "یک فایل در حال بارگذاری است لطفا صبر کنید تا فایل آپلود شود",
    "Autoresponder Black list means the list of phone numbers that you do not want the answering by Autoresponder":
      "لیست سیاه به معنای لیست شماره تلفن‌هایی است که نمی‌خواهید توسط پاسخگوی خودکار پیام‌های شان پاسخ داده شود",
    "Add the country code (for Iran: 98) to the first numbers":
      "کد کشور را (برای ایران: 98) به اول شماره ها اضافه کنید",
    "The blacklist only works for WhatsApp":
      "در حال حاضر لیست سیاه تنها برای پلتفرم واتساپ قابل استفاده است.",
    "The reporting of your account will be as minimal as possible":
      "ریپورت شدن حساب شما به حداقل ممکن خواهد رسید",
    "So far no ticket has been registered by you You can use the tickets to solve your problems":
      "تاکنون هیچ تیکتی توسط شما ثبت نشده است. می‌توانید از تیکت‌ها برای رفع مشکلات خود استفاده کنید",
    "Learning how to connect to the messenger and buy a subscription":
      "آموزش اتصال به پیامرسان و خرید اشتراک",
    "Rules and solutions for sending bulk messages on WhatsApp":
      "قوانین و راهکارهای ارسال پیام انبوه در واتساپ",
    "You must have an active subscription to use Inboxino services":
      "برای استفاده از خدمات اینباکسینو باید یک اشتراک فعال داشته باشید",
    "Note that each subscription must have the required service packages":
      "توجه داشته باشید که هر اشتراک باید بسته‌های مربوط به سرویس مورد نیاز را داشته باشد",
    "Numbers that are currently active in the system":
      "شماره‌هایی که در حال حاضر در سیستم فعال می‌باشند",
    "Currently, users who have a non-free package":
      "در حال حاضر کاربرانی که پکیج غیر رایگان دارند",
    "Number connection training and subscription purchase":
      "آموزش اتصال شماره و خرید اشتراک",
    "Bulk message status for the last 15 days":
      "وضعیت پیام‌های انبوه 15 روز گذشته",
    "View the complete list of various packages by selecting any category":
      "با انتخاب هر دسته بندی لیست کامل پکیج‌های متنوع را مشاهده کنید",
    "Please upload a file in xls or csv format":
      "لطفا یک فایل با فرمت xls یا csv بارگزاری کنید.",
    "You have not subscribed yet In order to use Inboxio services you must prepare a subscription and connect your desired messenger to it":
      "شما تاکنون اشتراکی تهیه نکرده اید. جهت استفاده از خدمات اینباکسینو باید یک اشتراک تهیه کرده و پیام‌رسان مورد نظر خود را به آن متصل کنید",
    "Are you sure to delete the subscription?":
      "آیا از حذف این اشتراک اطمینان دارید؟",
    "Are you sure the subscription is disconnected?":
      "آیا از قطع ارتباط ربات اطمینان دارید؟",
    "The request to disconnect your subscription has been registered This operation may take several minutes please be patient":
      "درخواست قطع ارتباط اشتراک متصل شما ثبت شد. این عملیات ممکن است چند دقیقه طول بکشد. لطفا صبور باشید.",
    "The connection was successfully made Now you can use Inboxino services":
      "اتصال با موفقیت انجام شد. اکنون می‌توانید از خدمات اینباکسینو استفاده کنید ",
    "Dear user the system is in final connection":
      " کاربر گرامی ، سیستم در حال اتصال نهایی می‌باشد.",
    "After connecting use Inboxino services easily":
      "پس از اتصال به راحتی از خدمات اینباکسینو استفاده کنید.",
    "Enter the WhatsApp program on your mobile phone with the number you want":
      "در گوشی همراه خود با شماره‌ی مورد نظرتان وارد برنامه واتساپ شوید.",
    "It is recommended to connect with your business number":
      "پیشنهاد می‌شود با شماره‌ی کسب و کار خود متصل شوید.",
    "Touch and then select Linked Devices option":
      "را لمس کرده و سپس گزینه‌ی Linked Devices را انتخاب کنید",
    "Hold the phone on the picture and wait for a few seconds":
      "گوشی را روی تصویر بگیرید و چند ثانیه منتظر بمانید.",
    "To connect your WhatsApp to the system to use Inboxino services please scan the qrcode according to the tutorial":
      "جهت اتصال واتساپ شما به سامانه جهت استفاده از خدمات اینباکسینو لطفا با توجه به راهنمای آموزشی، qrcode را اسکن کنید.",
    "After the scan wait until you receive a connection success message":
      "پس از اسکن تا دریافت پیام اتصال با موفقیت انجام شد، منتظر بمانید.",
    "The QR code for the user could not be uploaded Check out":
      "QR Code برای کاربر بارگزاری نشد. بررسی کنید.",
    "Unfortunately communication was not established We are trying to communicate":
      "متاسفانه ارتباط برقرار نشد. در حال تلاش برای برقراری ارتباط هستیم.",
    "qrcode created successfully scan qrcode":
      "qrcode با موفقیت ایجاد شد.qrcode را اسکن کنید",
    "Your WhatsApp is ready to work and does not need to be scanned":
      "واتساپ شما آماده به کار می‌باشد و نیاز به اسکن ندارد.",
    "Error in starting the WhatsApp number!":
      "خطا در شروع به کار شماره واتساپی!",
    "Note that after registering the information our colleagues will charge your wallet after confirming the correctness of the transaction":
      "توجه داشته باشید که پس از ثبت اطلاعات همکاران ما پس از تائید صحت انجام تراکنش ، کیف پول شما را شارژ خواهند کرد.",
    "You have not sent a message yet You can use the send message button to send a message":
      "شما تاکنون پیامی ارسال نکرده اید. برای ارسال پیام میتوانید از دکمه ارسال پیام استفاده نمائید.",
    "Are you sure you want to stop sending messages?":
      "آیا از توقف ارسال پیام اطمینان دارید؟",
    "Please put each number on a new line":
      "لطفا هر شماره را در یک خط جدید قرار دهید.",
    "Enter numbers without +98":
      "شماره هارا بدون 98+ وارد کنید.",
    "If you want to send multiple files (image, file, audio, etc), use the + button and create a new message with the desired file":
      "در صورتی که می‌خواهید چند فایل (تصویر، فایل، صوت و...) ارسال کنید، از دکمه‌ی + استفاده کنید و پیام جدیدی همراه با فایل موردنظر ایجاد کنید.",
    "If you want the audio file to be sent as voice, the format of the sent file must be OPUS":
      "اگر می‌خواهید فایل صوتی، به صورت ویس ارسال شود، فرمت فایل ارسالی باید OPUS یا OGG باشد.",
    "Time to start sending messages throughout the day":
      "ساعت شروع ارسال پیام‌ها در طول شبانه روز",
    "End time of sending messages during the day":
      "ساعت پایان ارسال پیام‌ها در طول شبانه روز",
    "The number of messages sent in each period":
      "تعداد ارسال پیامها در هر دوره",
    "To send you must confirm the rules and regulations of sending bulk messages":
      "جهت ارسال باید قوانین و مقررات ارسال پیام انبوه را تائید کنید.",
    "Please select your desired numbers from the phone book":
      "لطفا شماره‌های مورد نظر خود را از دفترچه تلفن انتخاب فرمایید.",
    "The purchased package does not have the ability to send bulk messages Or you have reached the maximum number of submissions":
      "پکیج خریداری شده قابلیت ارسال پیام انبوه را ندارد و یا حداکثر تعداد ارسال شما به پایان رسیده است",
    "Your sent file exceeds the allowed size of images and videos in subscription and must be sent as a file":
      "فایل ارسالی شما از حجم مجاز تصویر و ویدئو در اشتراک بیشتر است و باید به صورت فایل ارسال شود.",
    "Error uploading the file If this problem continues please let me know through a ticket":
      "خطا در آپلود فایل. در صورت ادامه این مشکل لطفا از طریق تیکت اطلاع دهید.",
    "You have not created an autoresponder yet":
      "شما تاکنون پاسخگوی خودکار ایجاد نکرده اید",
    "The text that will be displayed to users at the end of all your answers":
      "متنی که در انتهای تمامی پاسخ‌های شما به کاربران نمایش داده خواهد شد.",
    "You are deleting the selected Autoresponder are you sure":
      "شما در حال حذف پاسخگوی خودکار انتخاب شده هستید. آیا اطمینان دارید؟",
    "The number or text that the desired text (key description) will be sent to if the target user sends it to your Autoresponder":
      "عدد یا متنی که در صورتی که کاربر هدف برای پاسخگوی خودکار شما ارسال کند، متن مورد نظر (توضیح کلیدی) براش ارسال می‌شود.",
    "Welcome message (the first message sent)":
      "پیام خوش‌آمدگویی (اولین پیام ارسالی)",
    "Key description is the text that you want to display exactly to the target user":
      "توضیحات کلیدی، متنی است که می‌خواهید دقیقا به کاربر هدف نمایش دهید.",
    "Enter the text of the message that the recipient should receive":
      "متن پیامی که مخاطب باید دریافت کند را وارد کنید",
    "What is the display order of this menu?":
      "ترتیب نمایش این منو چگونه باشد؟",
    "You can use this option in the audio where you want to change the priority of the shows":
      "در صوتی که می‌خواهید اولویت نمایش‌های را تغییر دهید میتوانید ازین گزینه استفاده کنید.",
    "The welcome message should be sent to users only once":
      "پیام خوش‌‌آمد‌گویی تنها یک بار به کاربران ارسال شود",
    "It prevents the sending of repeated messages and fatigue of the target user":
      " باعث جلوگیری از ارسال پیام تکراری و خستگی کاربر هدف می‌شود.",
    "How many hours will the message not be sent?":
      "پیام تا چند ساعت ارسال نشود؟",
    "The welcome message will be re-sent to the user a few hours after sending the first welcome message":
      "پیام خوش آمدگویی تا چند ساعت بعد از ارسال اولین پیام خوش آمدگویی مجدد به کاربر ارسال شود.",
    "If this word is sent the welcome message will be sent to the user again":
      "در صورت ارسال این کلمه، پیام خوش‌آمد‌گویی مجددا برای کاربر ارسال خواهد شد",
    "Users who send messages to your Autoresponder their numbers are automatically saved in your phone book":
      "کاربرانی که به پاسخگوی خودکار شما پیام می‌دهند شماره آن‌ها در دفترچه تلفن شما خودکار ذخیره می‌گردد.",
    "Enter the phone book in which you want to save user numbers":
      "دفترچه تلفنی که می‌خواهید شماره‌های کاربران در آن ذخیره شود را وارد کنید.",
    "Create your Autoresponder welcome message":
      "ایجاد خوش‌آمد‌گویی پاسخگوی خودکار شما",
    "Create a title for the new Autoresponder":
      "یک عنوان برای پاسخگوی خودکار جدید ایجاد کنید.",
    "You have not used the web service yet You can use this section to connect to the website mobile application or any other program to send notifications Use the Create Web Service button to create a web service":
      "شما تاکنون از وب سرویس استفاده نکرده اید. ازین بخش میتوانید برای اتصال به وبسایت ، اپلیکشن موبایل و یا هر برنامه دیگری جهت ارسال اعلان‌ها استفاده کنید. برای ایجاد وب سرویس از دکمه ایجاد وب سرویس استفاده کنید",
    "By removing the api the programs that use this service will be disrupted Are you sure you want to delete this api?":
      "با حذف api برنامه‌هایی که از این سرویس استفاده میکنند با اختلال مواجه خواهند شد. آیا اطمینان دارید که می‌خواهید این api را حذف کنید؟",
    "subscription with Api package was not found To use the web service you must activate an active subscription that has the API package capability":
      "اشتراک دارای پکیج Api یافت نشد. برای استفاده از وب سرویس باید ربات فعالی که قابلیت پکیج API را دارد فعال کنید.",
    "The purchased package does not have the ability to use api":
      "پکیج خریداری شده قابلیت استفاده از api را ندارد",
    "What are the tips for cooperation in selling Inboxino?":
      " نکات همکاری در فروش اینباکسینو چیست ؟",
    "How can I send a proxy request?":
      "چطور می‌توان درخواست نمایندگی ارسال کرد؟",
    "How can I withdraw from my account?":
      "چطور می‌توانم از حسابم برداشت کنم ؟",
    "You are not allowed to withdraw more than the balance":
      "بیشتر از موجودی اجازه برداشت ندارید.",
    "To solve technical problems only response is done by ticketing For advice and non-technical questions you can contact the number":
      "برای حل مشکلات فنی فقط پاسخگویی با تیکتینگ انجام می‌شود. برای مشاوره و سوالات غیر فنی می‌توانید با شماره تماس",
    "Unfortunately a problem has occurred Refresh the site to fix the problem":
      "متاسفانه مشکلی رخ داده است. برای رفع مشکل سایت را رفرش کنید.",
    "The confirmation code will be sent to the entered mobile number via SMS and WhatsApp":
      "کد تائید به شماره موبایل وارد شده پیامک و واتساپ خواهد شد.",
    "Unfortunately there was an error sending the verification code":
      "متاسفانه در ارسال کد تایید خطایی رخ داده است.",
    "You have not created a phonebook yet You can use the phone book to manage your numbers":
      "تاکنون دفترچه تلفنی ایجاد نکرده اید. از دفترچه تلفن برای مدیریت شماره‌های خود می‌توانید استفاده کنید.",
    "Are you sure you want to delete this phonebook?":
      "آیا از حذف این دفترچه تلفن اطمینان دارید؟",
    "To add a number to this phone book, please send the Excel file of the list of numbers according to the sample file":
      "جهت اضافه کردن شماره به این دفترچه تلفن لطفا فایل اکسل لیست شماره‌ها را مطابق با فایل نمونه ارسال فرمایید.",
    "Are you sure of registering the numbers?":
      "آیا از ثبت شماره‌ها اطمینان دارید؟",
    "The numbers have been successfully added to the phonebook":
      "شماره‌ها با موفقیت به دفترچه تلفن اضافه شد.",
    "Are you sure you want to delete this":
      "آیا مطمئن هستید که می‌خواهید این را حذف کنید?",
    "Enter the verification code we sent to Whatsapp and SMS":
      "کد تأییدی را که با واتساپ و پیامک فرستادیم وارد کنید",
    "The internet connection was interrupted Please check your internet connection and then refresh the page for the system to work properly":
      "اتصال اینترنت قطع شد. لطفا اتصال اینترنت خود را بررسی کرده و سپس جهت عملکرد صحیح سامانه، صفحه را رفرش کنید",
    "Unfortunately there was an error in receiving the information":
      "متاسفانه در دریافت اطلاعات خطایی رخ داده است",
    "Please check your internet connection and refresh the page":
      "لطفا اتصال اینترنت خود را بررسی کرده و صفحه را رفرش کنید",
    "platforms field must have at least 1 items":
      "فیلد پلتفرم‌ها باید حداقل 1 مورد انتخاب شده باشد",
    "Every day we sends messages from the clock":
      "ارسال پیام هر روز از ساعت ",
    "Sends messages Then it waits until the start time of the messages":
      "پیام‌ها را ارسال می‌کند سپس تا ساعت شروع پیام‌ها منتظر می‌ماند",
    "It rests for a minute then starts sending again":
      "دقیقه وقفه ایجاد می‌شود و سپس دوباره شروع به ارسال می‌کند",
    "In column B: enter your first variable":
      "در ستون B : اولین متغیر خود را وارد کنید",
    "In column C: enter your second variable":
      "در ستون C : دومین متغیر خود را وارد کنید",
    "In column D: Enter your third variable":
      "در ستون D : سومین متغیر خود را وارد کنید",
    "The purchased package does not have the ability to send bulk messages Or you have reached the maximum number of submissions Or you have reached the maximum number of submissions":
      "پکیج خریداری شده قابلیت ارسال پیام انبوه را ندارد یا به حداکثر تعداد ارسالی رسیده‌اید یا به حداکثر تعداد ارسالی رسیده‌اید",
    "Sending bulk messages on WhatsApp is one of the best tools for advertising and information because of its high feedbackSending bulk messages on WhatsApp is one of the best tools for advertising and information because of its high feedback":
      "ارسال پیام‌های گروهی در واتساپ یکی از بهترین ابزارها برای تبلیغات و اطلاعات به دلیل بازخورد بالای آن است.",
    "Inboxino as one of the rare systems in the world that does this in the form of a web-based system provides you with the possibility to connect your WhatsApp to Inboxino without the need for a phone SIM card or systemInboxino as one of the rare systems in the world that does this in the form of a web-based system provides you with the possibility to connect your WhatsApp to Inboxino without the need for a phone SIM card or system":
      "اینباکسینو به عنوان یکی از سیستم‌های نادر در جهان که این کار را به صورت سیستم مبتنی بر وب انجام می‌دهد، امکان اتصال واتساپ خود را به اینباکسینو بدون نیاز به سیم‌کارت یا سیستم فراهم می‌کند.",
    "It is clear that you can send or receive messages on WhatsApp 24 hours a day using a dedicated serverIt is clear that you can send or receive messages on WhatsApp 24 hours a day using a dedicated server":
      "رسیدگی کردن به پیام‌ها 24 ساعته در واتساپ با استفاده از سرور اختصاصی ممکن است.",
    "There is no limit for sending files photos videos or links in Inboxino and also in terms of the number of messages sent except for the free package that only allows you to send 50 messages as a test the rest of the timed packages do not have anyThere is no limit for sending files photos videos or links in Inboxino and also in terms of the number of messages sent except for the free package that only allows you to send 50 messages as a test the rest of the timed packages do not have any":
      "در اینباکسینو محدودیتی برای ارسال فایل‌ها، عکس‌ها، ویدیوها یا لینک‌ها وجود ندارد و همچنین در مورد تعداد پیام‌های ارسالی، به جز بسته رایگان که فقط به شما اجازه می‌دهد ۵۰ پیام به عنوان آزمایش ارسال کنید، بسته‌های زمان‌بندی شده دیگر هیچ محدودیتی ندارند.",
    "There is no limit to the number of messages sent per dayThere is no limit to the number of messages sent per day":
      "محدودیتی برای تعداد پیام‌های ارسالی در روز وجود ندارد.",
    "But WhatsApp like all other messengers uses special algorithms to control the sending of advertising messages which you need to be aware of before sending the messages in order to minimize the problems of REPORT or BLOCK or limiting your WhatsApp numberBut WhatsApp like all other messengers uses special algorithms to control the sending of advertising messages which you need to be aware of before sending the messages in order to minimize the problems of REPORT or BLOCK or limiting your WhatsApp number":
      "اما واتساپ همانند سایر پیام‌رسان‌ها از الگوریتم‌های ویژه‌ای برای کنترل ارسال پیام‌های تبلیغاتی استفاده می‌کند که قبل از ارسال پیام‌ها باید از آن‌ها آگاه شوید تا مشکلات گزارش، مسدودسازی یا محدودیت شماره واتساپ شما کمتر شود.",
    "So please pay attention to the followingSo please pay attention to the following":
      "لطفاً به موارد زیر توجه کنید.",
    "First of all try to make sure that the WhatsApp number you are using is not your main number":
      "اولاً سعی کنید مطمئن شوید که شماره WhatsAppی که استفاده می‌کنید، شماره اصلی شما نیست.",
    "Since anyone can easily get 10 SIM cards in Iran and an unlimited number of internal and external virtual numbers at a low price so put numbers other than your main number to send messages":
      "از آنجا که هر کسی به راحتی می‌تواند ۱۰ کارت سیم کارت در ایران و تعداد نامحدودی شماره مجازی داخلی و خارجی را با قیمت پایین تهیه کند، بنابراین برای ارسال پیام‌ها از شماره‌هایی جز شماره اصلی خود استفاده کنید.",
    "Of course this restriction is only for sending advertising messages otherwise there is no restriction on the WhatsApp message or sending messages through your site or the messages you send to your customers and contacts":
      "البته این محدودیت فقط برای ارسال پیام‌های تبلیغاتی است و در غیر این صورت محدودیتی در پیام WhatsApp یا ارسال پیام‌ها از طریق سایت شما یا پیام‌هایی که به مشتریان و مخاطبان خود می‌فرستید، وجود ندارد.",
    "WhatsApp is not sensitive to users with whom you have already chatted or have a so-called chat history":
      "WhatsApp حساسیتی نسبت به کاربرانی که قبلاً با آنها چت کرده اید یا تاریخچه چتی دارید، ندارد.",
    "So try to create a two-way communication with the audience":
      "بنابراین سعی کنید ارتباط دو طرفه ای با مخاطبان خود برقرار کنید.",
    "The simple solution that we suggest is to send a regular message with the title Hello good morning to the audience most of the audience will respond to you after receiving such a message and then send your advertising message":
      "راه حل ساده ای که ما پیشنهاد می‌کنیم، ارسال یک پیام منظم با عنوان سلام صبح بخیر به مخاطبان است. بیشتر مخاطبان پس از دریافت چنین پیامی به شما پاسخ خواهند داد و سپس پیام تبلیغاتی خود را ارسال کنید.",
    "Leave a space between your sent messages which is easily possible through Inboxinos advanced settings and you can easily specify the time day or interval of sending messages":
      "فاصله ای بین پیام‌های ارسالی خود را رعایت کنید که از طریق تنظیمات پیشرفته Inboxino به راحتی امکان پذیر است و می‌توانید به راحتی زمان، روز یا فاصله زمانی ارسال پیام‌ها را مشخص کنید.",
    "In WhatsApp you should not behave like a robot and try to exchange daily and non-repetitive messages with your acquaintances":
      "در WhatsApp نباید مانند یک ربات رفتار کنید و سعی کنید پیام‌های روزانه و غیر تکراری با آشنایان خود تبادل کنید.",
    "Do not repeat your messages too much and try to change your writing type and content every 100 messages":
      "پیام‌های خود را خیلی زیاد تکرار نکنید و سعی کنید هر ۱۰۰ پیام، نوع نوشتار و محتوای آن را تغییر دهید.",
    "Inboxino has an option so that if you have the names of your contacts you can send messages with variables which means that you can create personalized messages even with the name and surname of each person which means that each of your messages is different from the other":
      "Inboxino دارای یک گزینه است که اگر نام‌های مخاطبان خود را داشته باشید، می‌توانید پیام‌هایی با متغیرها ارسال کنید که به این معنی است که می‌توانید پیام‌های شخصی سازی شده حتی با نام و نام خانوادگی هر شخص ارسال کنید که به این معنی است که هر یک از پیام‌های شما باقی مانده، متفاوت است.",
    "so that messages are not sent repeatedly": "تا پیام‌ها تکراری ارسال نشوند",
    "Try not to advertise your message too much and adjust the text of your message in such a way that it is informal friendly and polite so that your audience does not feel bad about receiving your message and does not report your line":
      "سعی کنید پیام خود را به اندازه کافی تبلیغ نکنید و متن پیام خود را به گونه‌ای تنظیم کنید که غیررسمی، دوستانه و مودبانه باشد، تا مخاطبان احساس ناراحتی نکنند و از دریافت پیام شما خوشحال شوند و خط شما را گزارش ندهند.",
    "Choose your bank number from the correct category for example if your activity is in a specialized and industrial field you dont need to send messages to students because your message will disturb these contacts":
      "شماره بانکی خود را از دسته‌بندی صحیح انتخاب کنید؛ به عنوان مثال، اگر فعالیت شما در زمینه تخصصی و صنعتی است، نیازی به ارسال پیام به دانشجویان ندارید؛ زیرا پیام شما ممکن است مخاطبان این دسته را آزار دهد.",
    "So never send messages to guilds that are not your target audience because they will not give you feedback":
      "بنابراین هرگز پیامی را به صنف‌هایی که جمعیت هدف شما نیستند، ارسال نکنید؛ زیرا آنها به شما بازخورد نخواهند داد.",
    "WhatsApp has a special privilege for old lines that are old in these messengers and rarely blocks them so try to use lines that have a history of more activity in WhatsApp":
      "واتساپ برای خطوط قدیمی که در این پیام‌رسان‌ها قدمت دارند، امتیاز ویژه‌ای دارد و به ندرت آنها را مسدود می‌کند؛ بنابراین سعی کنید از خطوطی استفاده کنید که سابقه فعالیت بیشتری در واتساپ دارند.",
    "If your lines are newly registered on WhatsApp you should try to increase the score of that line with messages and chats that the audience responds to":
      "اگر خطوط شما به تازگی در واتساپ ثبت‌نام کرده‌اند، باید سعی کنید با پیام‌ها و چت‌هایی که مخاطبان به آنها پاسخ می‌دهند، امتیاز آن خط را افزایش دهید.",
    "Use content that has an answer for example ask the audience to give you your answer for example if they want your product send the number 1 or if they dont want this type of content to be sent to them send the word off":
      "از محتواهایی که پاسخ دارند استفاده کنید؛ به عنوان مثال، از مخاطبان بخواهید پاسخ خود را بدهند؛ به عنوان مثال، اگر می‌خواهند محصول شما را بخرند، عدد ۱ را ارسال کنند، و اگر نمی‌خواهند این نوع محتوا به آنها ارسال شود، کلمه off را ارسال کنند.",
    "This response of contacts is very important for WhatsApp and prevents the line from being blocked":
      "این پاسخ مخاطبان بسیار مهم برای واتساپ است و مانع مسدود شدن خط می‌شود.",
    "On your WhatsApp line from the WhatsApp Autoresponder section in Inboxino activate Autoresponder so that if the contact replies he will send you automatic messages 24 hours a day":
      "در خط واتساپ خود از بخش واتساپ پاسخگوی خودکار در اینباکسینو، پاسخگوی خودکار را فعال کنید، تا اگر مخاطب پاسخ دهد، پیام‌های خودکار ۲۴ ساعته را برای شما ارسال کند.",
    "This greatly reduces the cost of manpower and responsiveness to customers":
      "این به طرز چشم‌گیری هزینه نیروی انسانی و پاسخگویی به مشتریان را کاهش می‌دهد.",
    "Put a photo for your WhatsApp profile and choose a suitable name and use more human photos like faces this is very effective in getting your feedback":
      "برای پروفایل واتساپ خود عکسی قرار دهید و نام مناسبی را انتخاب کنید و از تصاویر انسانی بیشتری مانند چهره‌ها استفاده کنید؛ این بسیار موثر در دریافت بازخورد شماست.",
    "If you connect your site to WhatsApp through Inboxino your notifications will be easily sent to people who visit your site":
      "اگر سایت خود را از طریق اینباکسینو به واتساپ متصل کنید، اعلان‌ها شما به راحتی به افرادی که سایت شما را بازدید می‌کنند، ارسال خواهد شد.",
    "Attention ! Your account information must match the name registered in the system":
      "توجه! اطلاعات حساب شما باید با نامی که در سیستم ثبت شده است مطابقت داشته باشد.",
    "Note that the amount to the card number or account number registered in the department profile You will be deposited Therefore if you have not entered your account information refer to the section profile Complete your account information":
      "لطفاً توجه داشته باشید که مبلغی که به شماره کارت یا شماره حسابی که در پروفایل بخش ثبت شده است واریز خواهد شد. بنابراین، اگر اطلاعات حساب خود را وارد نکرده‌اید، به بخش پروفایل مراجعه کرده و اطلاعات حساب خود را کامل کنید.",
    "Inboxino WhatsApp assistant is a web-based system that has gained attention due to the increasing use of WhatsApp by people and businesses":
      "Inboxino دستیار واتساپ است سیستمی مبتنی بر وب است که به دلیل استفاده روزافزون واتساپ توسط مردم و کسب‌وکارها توجه بسیاری را به خود جلب کرده است",
    "Definitely, both you and those around you do a lot of daily affairs, communication, notifications or advertisements on WhatsApp":
      "بدون شک، شما و افراد اطراف شما بسیاری از امور روزمره، ارتباطات، اعلان‌ها یا تبلیغات را در واتساپ انجام می‌دهید",
    "This large volume in the use of this software has caused many businesses to turn to WhatsApp and transfer their communication with customers, marketing and advertising, customer support to WhatsApp":
      "حجم بزرگ استفاده از این نرم‌افزار باعث شده است که بسیاری از کسب‌وکارها به واتساپ متوجه شوند و ارتباط خود را با مشتریان، بازاریابی و تبلیغات، پشتیبانی مشتریان را به واتساپ منتقل کنند",
    "Since WhatsApp, unlike Telegram, has numerous limitations for wide and mass use, a group of programming and artificial intelligence experts gathered in Inboxino to make the use of WhatsApp easier for businesses and to connect WhatsApp with sites":
      "از آنجا که واتساپ، برخلاف تلگرام، محدودیت‌های بسیاری برای استفاده گسترده و جمعی دارد، یک گروه از کارشناسان برنامه‌نویسی و هوش مصنوعی در Inboxino گرد هم آمده‌اند تا استفاده از واتساپ را برای کسب‌وکارها آسان‌تر کنند و واتساپ را با سایت‌ها ارتباط دهند",
    "systems various applications creating automatic answering machines creating stores and queuing systems in WhatsApp as well as sending mass messages in WhatsApp the concern of companies organizations and businesses in using WhatsApp as a message Lets raise the world":
      "ایجاد سیستم‌ها و برنامه‌های مختلف برای ایجاد دستگاه‌های پاسخگویی خودکار، ایجاد فروشگاه‌ها و سیستم‌های صف‌زنی در واتساپ، و همچنین ارسال پیام‌های گروهی در واتساپ، نگرانی شرکت‌ها، سازمان‌ها و کسب‌وکارها در استفاده از واتساپ به عنوان یک ابزار ارتباطی را در جهان بالا می‌برد",
    "As mentioned, Inboxino WhatsApp Assistant provides the following services on the web platform:":
      "همانطور که ذکر شد، Inboxino دستیار واتساپ، خدمات زیر را در پلتفرم وب ارائه می‌دهد:",
    "By creating a Autoresponder on WhatsApp, you can make it easier for you to respond to your customers and audience":
      "با ایجاد یک پاسخگوی خودکار در واتساپ، می‌توانید پاسخگویی به مشتریان و مخاطبان خود را آسان‌تر کنید",
    "In this way, this autoresponder is programmed and executed 24 hours a day and fully automatically by you and with your own business numbers, and anyone who sends a message to your WhatsApp number will receive a welcome message at first":
      "به این ترتیب، این پاسخگوی خودکار به صورت 24 ساعته و کاملاً خودکار توسط شما و با شماره تجاری خود برنامه‌ریزی و اجرا می‌شود و هر کسی که پیامی به شماره واتساپ شما ارسال کند، ابتدا پیامی خوش‌آمدگویی دریافت می‌کند",
    "or example, consider someone sends a phrase like hello to the WhatsApp number of Inboxino, and the system automatically sends this message to him":
      "برای مثال، فرض کنید کسی عبارتی مانند سلام را به شماره واتساپ Inboxino ارسال می‌کند و سیستم به طور خودکار این پیام را به او ارسال می‌کند",
    "Please select the desired number from the menu below to use any of Inboxinos services":
      "لطفاً شماره مورد نظر را از منوی زیر انتخاب کنید تا از هر یک از خدمات Inboxino استفاده کنید",
    "As you can see you can create a menu and sub-menu in WhatsApp in several steps and after each user selects the desired menu in the form of a complete WhatsApp bot you will have an automatic response within a few seconds and a photo":
      "همانطور که مشاهده می‌کنید، می‌توانید با چند مرحله یک منو و زیرمنو در واتساپ ایجاد کنید و بعد از انتخاب هر کاربر منوی مورد نظر، به صورت یک ربات کامل در واتساپ، در عرض چند ثانیه پاسخ خودکار و تصویری خواهید داشت",
    "Send video, audio and text or any content you have in mind to your contacts":
      "ویدیو، صدا و متن یا هر محتوای دیگری که در ذهن دارید را به مخاطبان خود ارسال کنید",
    "This Autoresponder is very effective for many organizations companies or even natural persons it can greatly reduce the cost of manpower and waste of time and energy to respond to customers and contacts You can follow the guide for making a Autoresponder in this link":
      "این پاسخگوی خودکار بسیار موثر برای بسیاری از سازمان‌ها، شرکت‌ها و حتی افراد طبیعی است و می‌تواند هزینه نیروی کار و هدررفت زمان و انرژی برای پاسخ به مشتریان و ارتباط با مخاطبان را به شدت کاهش دهد. می‌توانید راهنمای ساخت یک پاسخگوی خودکار را در این لینک دنبال کنید",
    "All businesses and organizations are interested in being able to introduce their brand to thousands or even millions of people either for advertising or for information and to inform their customers and clients of a special offer or news":
      "تمام کسب‌وکارها و سازمان‌ها علاقه‌مند هستند تا بتوانند برند خود را به هزاران یا حتی میلیون‌ها نفر معرفی کنند، سرآمد کنند یا اطلاعاتی ارائه دهند و مشتریان و مشتریان خود را از یک پیشنهاد ویژه یا اخبار مطلع کنند",
    "Most of the businesses were using text messages or sms before but due to the many limitations of text messages such as many numbers being blocked messages not being read not sending videos photos and audio the bulk sending being expensive and or the lack of proper feedback they prohibit sending messages through SMS":
      "بیشتر کسب و کارها قبلاً از پیامک‌های متنی یا اس‌ام‌اس استفاده می‌کردند اما به دلیل محدودیت‌های زیاد پیامک‌های متنی مانند بلاک شدن شماره‌های زیاد، عدم خوانده شدن پیام‌ها، عدم امکان ارسال ویدئوها، عکس‌ها و صداها، هزینه بالای ارسال جمعی و یا عدم بازخورد مناسب، آن‌ها ارسال پیام از طریق اس‌ام‌اس را ممنوع کرده‌اند",
    "But the Inboxino system has made it possible for all organizations and businesses to send video photo audio link or text to any number they want to the number of numbers they want":
      "اما سیستم Inboxino به تمام سازمان‌ها و کسب و کارها امکان ارسال ویدئو، عکس، صدا، لینک یا متن به هر شماره‌ای که می‌خواهند و تعدادی که می‌خواهند فراهم کرده است",
    "It is high and that more than 90% of the messages are checked by your contacts it can be the best tool for information or advertising and sales":
      "با توجه به اینکه بالای 90٪ از پیام‌ها توسط مخاطبان شما چک می‌شوند، این می‌تواند بهترین ابزار برای اطلاع‌رسانی یا تبلیغات و فروش باشد",
    "Of course keep in mind that there is no limit to send any number of messages in the Inboxino system due to time sharing but WhatsApp has limits for numbers that you have not chatted with before and if they report you":
      "البته به خاطر اشتراک زمانی، در سیستم Inboxino محدودیتی در ارسال هر تعداد پیام وجود ندارد، اما WhatsApp محدودیت‌هایی برای شماره‌هایی که قبلاً چت نکرده‌اید و در صورت گزارش شما وجود دارد",
    "It can and may block your number which of course will return to normal after a few hours or days but before you start sending messages please see the guide and tutorial on how to reduce the risk of being blocked in the link below":
      "ممکن است شماره شما را بلاک کند که البته بعد از چند ساعت یا روز به حالت عادی بازمی‌گردد، اما قبل از شروع ارسال پیام‌ها، لطفاً راهنما و آموزش کاهش خطر بلاک شدن را در لینک زیر مشاهده کنید",
    "Another useful feature of Inboxino is that you can connect your site application or systems to your WhatsApp so that you can automatically send any message you want to your users":
      "یکی از ویژگی‌های مفید دیگر Inboxino این است که شما می‌توانید سایت، برنامه یا سیستم خود را به WhatsApp متصل کنید تا هر پیامی که می‌خواهید به کاربران خود ارسال کنید",
    "For example sending a confirmation code on WhatsApp to users who are entering your site or sending training videos to customers who are looking for a guide to using your facilities or anyone who sends an article in the chat section of your site can see it on their WhatsApp":
      "به عنوان مثال، ارسال کد تأیید به کاربرانی که وارد سایت شما می‌شوند یا ارسال ویدئوهای آموزشی به مشتریانی که به دنبال راهنمای استفاده از امکانات شما هستند یا هر کسی که یک مقاله را در بخش چت سایت شما ارسال کند، می‌تواند آن را در WhatsApp خود ببیند",
    "do This feature which is provided to you through the web service or api can also provide you with hundreds of other features such as sending reminders notifications customer crm etc":
      "این ویژگی که از طریق سرویس وب یا آپی به شما ارائه می‌شود، می‌تواند شما را به صدها ویژگی دیگر مانند ارسال یادآورها، اعلان‌ها، crm مشتری و غیره، تامین کند",
    "To receive and activate the web service after buying the package related to this feature click on the web service option from the side menu":
      "برای دریافت و فعال‌سازی سرویس وب پس از خرید بسته مربوط به این ویژگی، بر روی گزینه سرویس وب از منوی کناری کلیک کنید",
    "From the api documentation section read and execute the required documents":
      "از بخش مستندات آپی مقالات مورد نیاز را بخوانید و اجرا کنید",
    "This possibility is very useful for those businesses that run their business through queuing systems":
      "این امکان بسیار مفید برای آن کسب و کارهایی است که کسب و کار خود را از طریق سیستم‌های صف‌های انتظار انجام می‌دهند",
    "In this way you provide your WhatsApp number to the customers and after sending a message on WhatsApp they see all the times specified by you and book their appointment on WhatsApp":
      "به این ترتیب شما شماره WhatsApp خود را به مشتریان ارائه می‌دهید و پس از ارسال پیام در WhatsApp، زمان‌های مشخص شده توسط شما را مشاهده کرده و وقت خود را در WhatsApp رزرو می‌کنند",
    "Even after booking an appointment they can pay online on WhatsApp":
      "حتی پس از رزرو وقت، آن‌ها می‌توانند آنلاین در WhatsApp پرداخت کنند",
    "After booking the appointment a WhatsApp message is sent to the business owner and informs him of the reservation":
      "پس از رزرو وقت، پیامی به صاحب کسب و کار ارسال می‌شود و او را از رزرو آگاه می‌سازد",
    "This possibility which is considered a rare example in the world is very useful for doctors consultants hairdressers and as mentioned all businesses that are somehow involved in booking appointments":
      "این امکان که به عنوان یک مثال نادر در جهان در نظر گرفته می‌شود، برای دکتران، مشاوران و آرایشگران و همانطور که گفته شد تمام کسب و کارهایی که به هر شکلی در رزرو وقت دارند بسیار مفید است",
    "Since many of our daily interactions and communications happen on WhatsApp it is much easier for both customers and business owners to get support on WhatsApp than on different software":
      "از آنجایی که بسیاری از تعاملات روزانه و ارتباطات ما در WhatsApp اتفاق می‌افتد، برای همه مشتریان و صاحبان کسب و کار، دریافت پشتیبانی از طریق WhatsApp نسبت به نرم‌افزارهای مختلف آسانتر است",
    "By connecting CRM systems of businesses to WhatsApp, Inboxino has been able to drastically reduce the costs of answering and communicating with many businesses":
      "با اتصال سیستم‌های crm کسب و کارها به WhatsApp، Inboxino توانسته است هزینه‌های پاسخگویی و ارتباط با بسیاری از کسب و کارها را به طور چشمگیری کاهش دهد",
    "Inboxino has made it possible for you to view the messages received from your customers online in Inboxino and send messages to your contacts from the same section":
      "Inboxino این امکان را برای شما فراهم کرده است که پیام‌های دریافتی از مشتریان خود را آنلاین در Inboxino مشاهده کنید و پیام‌ها را از همان بخش به مخاطبان خود ارسال کنید",
    "This feature allows multiple users to respond to customers":
      "این ویژگی امکان پاسخگویی به مشتریان را توسط چند کاربر فراهم می‌کند",
    "For information and methods of setting up and using each of the above features you can refer to the following link":
      "برای اطلاعات و روش‌های تنظیم و استفاده از هر یک از ویژگی‌های فوق، می‌توانید به لینک زیر مراجعه کنید",
    "Also to answer questions you can call 021-91690322 through ticket online chat and phone number":
      "همچنین برای پاسخ به سوالات می‌توانید از طریق تیکت، چت آنلاین و شماره تلفن 021-91690322 تماس بگیرید",
    "Also our colleagues are ready to answer your questions on WhatsApp with":
      "همچنین همکاران ما آماده پاسخگویی به سوالات شما در WhatsApp هستند",
    "Customer support in WhatsApp (WhatsApp crm)":
      "پشتیبانی مشتری در واتساپ (WhatsApp crm)",
    "As you can see an api key will be provided to you":
      "همانطور که می‌بینید یک کلید API برای شما در اختیار قرار می‌گیرد",
    "Connecting WhatsApp to the site through web service (api)":
      "اتصال واتساپ به سایت از طریق خدمات وب (api)",
    "The password must contain at least one uppercase letter one lowercase letter one digit and have a minimum length of 8 characters":
      "رمزعبور باید شامل حداقل یک حرف بزرگ، یک حرف کوچک و یک عدد باشد و طول حداقل ۸ کاراکتر باشد",
    "Inboxino is an internal and external messaging assistant that allows you to send bulk messages with your own number to multiple recipients on messaging apps such as WhatsApp Telegram Eitaa and Bale You can send text messages photos videos audios and more Additionally you can set up an auto-reply for your own number or business so whenever someone messages you on any messaging platform you can respond with predefined options Furthermore you can connect your website application or CRM to Inboxino via the web service to send automated notifications":
      "اینباکسینو دستیار پیام‌رسان‌های داخلی و خارجیه که میتونی باهاش از طریق شماره خودت به صورت انبوه برای هرچند نفر که میخوای در واتس اپ، تلگرام، ایتا و بله ، عکس و فیلم و صوت و پیام بفرستی، یا میتونی روی شماره خودت یا کسب کارت منشی پاسخگو بذاری تا هر کی بهت تو هر پیام‌رسانی پیام داد به صورت منو بندی شده بهش پیام بدی یا اینکه سایت ، اپلیکیشن یا CRM خودت رو از طریق وب سرویس به اینباکسینو وصل کنی تا نوتیفیکیشن خودکار ارسال کنی",
    "Click on My Subscriptions in the side menu and create a new subscription on the opened page":
      "از منوی کناری، بر روی اشتراک‌های من کلیک کنید و در صفحه باز شده یک اشتراک جدید ایجاد کنید.",
    "After creating the subscription go to Manage Platforms by clicking on the button in My Subscriptions menu and connect your messengers":
      "پس از ایجاد اشتراک، در منوی اشتراک‌های من برو روی دکمه مدیریت پلتفرم‌ها کلیک کنید و پیام‌رسان‌های خود را متصل کنید.",
    "Now Inboxino is ready to serve you Use our services from the menu and enjoy":
      "اکنون، اینباکسینو آماده خدمت رسانی به شما می‌باشد. از منو خدمات ما استفاده کنید و لذت ببرید.",
    "Your free subscription has been activated":
      "اشتراک رایگان برای شما فعال شد",
    "Now please connect your messengers":
      "اکنون پیام‌رسان‌های خود را متصل کنید",
    "Webhooks are used for instant notification and real-time events in systems For example a webhook can be used to notify important events such as sending a message updating a status or other events You can receive this information in your system through an API and perform the necessary operations":
      "وب هوک‌ها برای اطلاع‌رسانی فوری و رویدادهای بروز در سیستم‌ها استفاده می‌شوند به عنوان مثال وب هوک می‌تواند برای اطلاع‌رسانی در مورد رویدادهای مهمی مانند ارسال یک پیام بروزرسانی یک وضعیت یا رخدادهای دیگر مورد استفاده قرار گیرد و شما میتوانید از طریق Api این اطلاعات را در سیستم خود دریافت کنید و عملیات مناسب را انجام دهید",
    "Please replace the required variables in the text (A) and (B) with your own For example Hello (A) Our phone number is (B)":
      "لطفاً متغیرهای مورد نیاز را در متن (A) و (B) خودتان جایگزین کنید. به عنوان مثال، سلام (A). شماره تلفن ما (B) است.",
    "Free trial for {days} days with a limit of {limit} messages will be activated for you by clicking the continue button":
      "اشتراک تست رایگان (به مدت {days} روز تا سقف ارسال {limit} پیام) با کلیک بر روی دکمه ادامه برای شما فعال خواهد شد",
    "On which platforms do you want to send messages?":
      "در کدام پلتفرم‌ها می‌خواهید پیام‌ها را ارسال کنید؟",
    "Enter the numbers you want to send a message to":
      "شماره هایی را که میخواهید پیام برایشان ارسال شود وارد کنید.",
    "The maximum number that can be entered in the free test mode is {number} numbers, of which only {message} messages will be sent.":
      "حداکثر شماره قابل ورود در حالت تست رایگان {number} شماره می‌باشد که ازین تعداد فقط {message} پیام ارسال می‌شود.",
    "In the free test mode, only {message} messages are sent. But because some numbers may not have WhatsApp, you can enter up to {number} numbers.":
      "در حالت تست رایگان، فقط {message} پیام ارسال می‌شود.اما به دلیل اینکه ممکن است برخی از شماره ها پلتفرم موردنظر را نداشته باشند، شما میتوانید تا سقف {number} شماره وارد کنید.",
    "To test our service, please click on the button {name}":
      "برای تست سرویس ما، لطفا روی دکمه {name} کلیک کنید",
    "The maximum allowed entry of Excel numbers from the free test mode is {number} numbers":
      "سقف مجاز ورود شماره اکسل در حالت تست رایگان {number} شماره می‌باشد.",
    "”The number of numbers” to which the message is sent?":
      " ”تعداد شماره‌هایی” که پیام برای آنها ارسال می‌شود ؟",
    "With which subscription is it sent?":
      "با کدام اشتراک ارسال انجام می‌شود ؟",
    "Your message is placed in the sending queue and will be sent in a few minutes، Your phone does not need to be connected to the Internet anymore":
      "پیام شما در صف ارسال قرار قرار گرفته و تا دقایقی دیگر ارسال میشود،دیگر نیازی نیست گوشی شما به اینترنت متصل باشد",
    "At least one platform connection is required":
      "اتصال حداقل یک پلتفرم الزامی است.",
    "Enter the number of people you want to send a message to.":
      "شماره افرادی که می‌خواهید برای آنها پیام ارسال شود را وارد کنید.",
    "You cannot enter more than 100 numbers for testing.":
      "برای تست بیشتر از 100 شماره نمیتوانید وارد کنید.",
    "The maximum allowed entry of Excel numbers is {number} numbers":
      "سقف مجاز ورود شماره اکسل {number} شماره می‌باشد",
    "9% value added tax is added to the above amount":
      "به مبلغ فوق 9% مالیات بر ارزش افزوده اضافه می‌گردد",
    "Please check the following and continue if correct":
      "لطفا موارد زیر را بررسی کنید و در صورت صحیح بودن ادامه دهید",
    "According to the guide, please enter the displayed code in your WhatsApp mobile and wait for the connection message":
      "طبق راهنما لطفا کد نمایش داده شده را در واتساپ موبایل خود وارد کنید و منتظر پیام اتصال بمانید",
    "From now on, you don't need to turn on the filter and turn on the system":
      "از این پس نیازی به فیلترشکن و روشن بودن سیستم ندارید",
    "Do you want to disconnect this platform in Inboxino?":
      "'آیا می‌خواهید ارتباط این پلتفرم را در اینباکسینو قطع کنید؟'",
    "Web service for notifications (alerts) is provided in the table below. Use this web service for your notifications (alerts).":
      "وب سرویس نوتیفیکیشن (اعلان) در جدول زیر آورده شده است. از این وب سرویس برای نوتیفیکیشن (اعلان) خود استفاده کنید.",
    "Configure your notifications (alerts) according to the sample code provided below your messages, and based on the explanations provided below, send your data to the above web service to be able to send your notifications (alerts).":
      "برای نوتیفیکیشن (اعلان) طبق نمونه کد قرار داده شده در پایین پیام‌های خود را تنظیم کنید و با توجه به توضیحات آورده در پایین دیتا خود را به وب سرویس بالا ارسال کنید تا نوتیفیکیشن (اعلان) خود را به بتوانید ارسال کنید.",
    "In the table below, the types of parameters along with how to correctly send them and their explanations are provided. Please read carefully and send your messages according to the requested data.":
      "در جدول زیر، نوع پارامترها به همراه نحوه ارسال صحیح و توضیحات آن‌ها قرار داده شده است. لطفا با دقت مطالعه فرمائید و پیام‌های خود را طبق داده‌های خواسته شده ارسال کنید.",
    "It is an array of messages, and each array is the necessary data for sending each message. To send multiple messages to one number, you must place multiple arrays of messages in this section. For example, two messages will now be sent to the number 09376080467.":
      "آرایه ای از پیام‌ها می‌باشد که هر آرایه دیتای مورد نیاز برای ارسال هر پیام است. برای ارسال چندین پیام برای یک شماره، باید چندین ارایه از پیام‌ها در این قسمت قرار بدید. برای مثال اکنون دو پیام برای شماره 09376080467 ارسال خواهد شد.",
    "Specifies the type of message you are sending, and your message type can only be one of the values message | image | video | file | audio. Images, files, or ... should be uploaded via the web service and placed in the attachment_file section.":
      "نوع پیامی که ارسال می‌کنید را مشخص می‌کند و نوع پیام شما فقط می‌تواند یکی از مقادیر message | image | video | file | audio باشد. تصویر، فایل یا ... باید از طریق وب سرویس آپلود بارگزاری شوند و در قسمت attachment_file قرار بگیرد",
    "If the message you are sending contains an image, video, file, or ... first upload your desired file through the Upload web service and replace the received file address in this section. Note that the message_type value must be completed according to the uploaded file. For example, if you have uploaded an image, the message_type should be sent as image.":
      "در صورتی که پیامی که ارسال میکنید حاوی تصویر، ویدیو، فایل یا ... می‌خواهید باشد، ابتدا فایل مورد نظر خود را از طریق وب سرویس Upload بارگزاری کنید و آدرس فایل دریافتی را در این قسمت جایگزین کنید. توجه داشته باشید که مقدار message_type باید طبق فایل آپلود  شده پر گردد. برای مثال اگر یک تصویر آپلود کرده باشید، message_type باید image ارسال گردد.",
    "If you want your desired file to be sent as a file in messengers so that its quality does not decrease, you must send this option as true.":
      "در صورت که فایل مورد نظر شما می‌خواهید به عنوان فایل در پیام‌رسان‌ها ارسال گردد تا از کیفیت آن کم نشود این گزینه را باید true بفرستید.",
    "Place the list of numbers you want to send messages to in this section.":
      "لیست شماره‌هایی که به آن می‌خواهید پیام ارسال کنید را در این قسمت قرار دهید.",
    "Send the platforms where you want the messages to be sent. Make sure your platform is connected to Inboxino. Select only the platforms that are active in the selected bot; otherwise, your messages will not be sent.":
      "  پلتفرم‌هایی که می‌خواهید در آن پیام‌ها ارسال شود را در این قسمت ارسال کنید. از متصل بودن پلتفرم خود در اینباکسینو اطمینان حاصل بفرمائید. تنها پلتفرم‌هایی که در ربات انتخاب شده فعال می‌باشند را انتخاب کنید در غیر این صورت پیام‌های شما ارسال نخواهند گردید.",
    "If the numbers are entered with the destination country code, this field should be off or false. If it is true, you should also send the country code (country_code).":
      "در صورتی که شماره‌ها با کد کشور مقصد وارد شده باشند، این فیلد باید خاموش یا false باشد. در صورتی که true باشد باید کد کشور(country_code) را هم باید ارسال کنید.",
    "Select the country you want to send the message to. The country code will be added to the beginning of the numbers. So send the numbers in the recipients field without the code. The default country is Iran.":
      "  کشوری که می‌خواهید پیام به آن ارسال گردد را انتخاب کنید. کد کشورانتخاب به ابتدای شماره‌ها اضافه می‌گردد. پس شماره‌ها را در فیلد recipients بدون کد ارسال کنید. کشور پیش‌فرض ایران می‌باشد.",
    "With this section, you can manage how to send your messages.":
      "با این قسمت می‌توانید نحوه ارسال پیام‌های خود را مدیریت کنید.",
    "Message expiry time. If a message, for example, is not sent within 3 minutes, it will no longer be sent.":
      "زمان انقضا ارسال یک پیام می‌باشد. درصورتی که یک پیام مثال درمدت زمان 3 دقیقه ارسال نشد، دیگر ارسال نمی‌شود.",
    "To send a file in your notification, first upload your files via the web service and replace the data according to the above documents.":
      "برای ارسال فایل در نوتیفیکیشن (اعلان) خود ابتدا از طریق وب سرویس زیرفایل‌های خود را آپلود کنید و با توجه به مستندات بالا، داده‌ها را جایگزین کنید.",
    "To send a file in your notification, first send your file via the above web service in the format shown below, then save the file path and place it in the notification web service.":
      "برای ارسال فایل در نوتیفیکیشن (اعلان) خود، ابتدا فایل خود را از طریق وب سرویس بالا و در فرمت مثال پایین ارسال کنید و سپس مسیر فایل را ذخیره کرده  و در وب سرویس نوتیفیکیشن (اعلان) قرار دهید.",
    "You can use this web service to get the list of sent messages":
      "برای دریافت لیست پیام‌های ارسال شده می‌توانید از این وب سرویس استفاده کنید",
    "You can use this web service to retrieve a list of sent messages.":
      "برای دریافت لیست پیام‌های ارسال شده می‌توانید از این وب سرویس استفاده کنید",
    "voice recording": "ضبط صدا",
    "stop recording": "توقف ضبط",
    "close ticket": "بستن تیکت",
    "sending limitation": "محدودیت ارسال",
    "From now on, you dont need to turn on the vpn and turn on the system":
      "دیگر نیاز نیست vpn و سیستم خود را روشن نگهدارید",
    "No need to connect vpn anymore":
      " دیگر نیازی به اتصال vpn نیست ",
    "According to WhatsApp rules, videos larger than 16 MB are sent as files":
      "طبق قوانین واتس اپ ویدیوهای بیشتر از 16 مگابایت به صورت فایل ارسال می‌گردند.",
    "In the Excel file, just like the sample file, enter your variables in order in columns B and on, and in the text of the sent message, enter the letter of the column instead of each variable, For example: Hello (B) dear":
      "در فایل اکسل همانند فایل نمونه در ستون‌های B به بعد متغیرهای خود را به ترتیب وارد کنید و در متن پیام ارسالی به جای هر متغیر حرف ستون آن را وارد کنید. به طور مثال: سلام (B) عزیز      "
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "fa",
  globalInjection: true,
  messages,
});

export default i18n;
