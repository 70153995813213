
import { defineComponent } from "vue";
import userInfo from "@/core/services/UserInfo";
import { translate } from "@/core/services/HelpersFun";
export default defineComponent({
  name: "kt-currency_code",
  components: {},
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  setup() {
    const user = userInfo.getUser();
    return {
      user,
      translate,
    };
  },
});
